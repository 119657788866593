import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ErrorCapture } from '../models/error.model';
import { catchError } from 'rxjs/operators';
import { ErrorResponseHandler } from '../shared/error.handler';

@Injectable({
	providedIn: 'root'
})
export class ErrorLoggingService {

	private endpoint: string;

	constructor(private http: HttpClient) {
		this.endpoint = environment.endpoint;
	}

	public captureError(error: ErrorCapture) : Promise<any> {
		
		error.data = this.blankPasswordInKey(error.data);
		console.log(error);
		return this.http.post<any>(this.endpoint + '/API/ErrorLogging/', error,
			{headers:{'Content-Type': 'application/json; charset=utf-8'}})
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	private blankPasswordInKey(data: string): string{
		let dataJSON = JSON.parse(data);

		if(dataJSON)
		{
			for(let [key,val] of Object.entries(dataJSON)){
				if(key === "password")
				{
					dataJSON[key] = "******";
				}
				else
				{
					let next = JSON.stringify(dataJSON[key]);
					if(next.indexOf("{") != -1 && next.indexOf("}") != -1)
					{
						dataJSON[key] = JSON.parse(this.blankPasswordInKey(next));
					}
				}
			}
		}

		return JSON.stringify(dataJSON);
	}
}
