import { StripeProductForm } from './stripe.model';
import { UserProfileRequest } from './userprofile.model';

export class Organisation {
  _id?: string;
  organisationId: number;
  organisationName: string;
  isDisabled: boolean;
  isInSignup: boolean;

  exampleAssets: string[];

  subscriptionStatus: string; // 'development','trial','active','unpaid','cancelled','reseller'
  extendedTrial:boolean;
  customerId: string;
  priceType: string; // 'standard','eduNFP','custom'
  productId: string;
  priceId: string;
  subscriptionId: string;
  subscriptionItemId: string;
  autoRenew: boolean;
  numOfUsers: number;
  billingPeriodStart: Date;
  billingPeriodEnd: Date;
  manualSubscription: boolean;

  isMasterOrg: boolean;
  masterOrgId: number;

  isResellerOrg: boolean;
  resellerOrgStripeId: string;
  resellerOrgStripeStatus: string;

  resellerOrgId: number;
  isResellerRevenueShare: boolean;

  isAllowedWhiteLabel: boolean;
  isWhiteLabelActive: boolean;
  isMasterOrgWhiteLabelActive: boolean;
  isAllSubOrgsWhiteLabelActive: boolean;
  whiteLabelContent: WhiteLabelContent;

  offlineAccess: boolean;
  dataRegion:string;
  storageLimit:number;
  creatorLicenseLimit:number;
  creatorLicensePriceId?: string;
  currency?: string;

  constructor(orgId: number, orgName: string) {
    this.organisationId = orgId,
    this.organisationName = orgName,
    this.isDisabled = false,
    this.isInSignup = false,

    this.exampleAssets = ["all"]

    this.subscriptionStatus = "trial", // can't be null, use this for safe default
    this.customerId = null;
    this.productId = null;
    this.priceId = null;
    this.subscriptionId = null;
    this.subscriptionItemId = null;
    this.autoRenew = false;
    this.numOfUsers = 0;
    this.billingPeriodStart = new Date(0,0,0,0,0,0,0);
    this.billingPeriodEnd = new Date(0,0,0,0,0,0,0);
    this.manualSubscription = false;

    this.isMasterOrg = false,
    this.masterOrgId = 0,

    this.isResellerOrg = false,
    this.resellerOrgStripeId = null;
    this.resellerOrgStripeStatus = null;
    
    this.resellerOrgId = 0,
    this.isResellerRevenueShare = false;

    this.isAllowedWhiteLabel = false,
    this.isWhiteLabelActive = false,
    this.isMasterOrgWhiteLabelActive = false,
    this.isAllSubOrgsWhiteLabelActive = false,
    this.whiteLabelContent = new WhiteLabelContent;

    this.offlineAccess = true;
  }
}

export class ActiveUsersCount{
  activeUsers: number;
  minUsers: number;
  bracketLimits: number[];

  constructor() {
    this.activeUsers = 0;
    this.minUsers = 0;
    this.bracketLimits = [];
  }
}

export class ActiveUsersPerTimePeriod {
  startDate: Date;
  endDate: Date;
  activeUsers: number;

  // constructor() {
  //   this.activeUsers = 0;
  //   this.minUsers = 0;
  //   this.bracketLimits = [];
  // }
}

export interface OrganisationRequest {
  organisationName: string;
  organisationId: number;
  subscriptionStatus: string;
  priceType: string;
  dataRegion:string;
  adminRequest: UserProfileRequest;
  //TEMP for manual only billing
  creatorLicenseLimit: number;
  isManualBilling: boolean;
  priceId:string;
  numOfUsers:number;
  resellerOrgStripeStatus:string;
  
}

export interface OrgLinkInvite {
  id: string;
  invitingOrgId: number;
  inviteName: string;
  type: string;
  manualSubscription: boolean;
}

export class WhiteLabelContent {
  webContent: WhiteLabelContentWeb;
  appContent: WhiteLabelContentApp;

  constructor() {
    this.webContent = new WhiteLabelContentWeb;
    this.appContent = new WhiteLabelContentApp;
  }
}

export class WhiteLabelContentWeb {
  welcomeMessage: string;
  navBarLogo: string;

  constructor() {
    this.welcomeMessage = null;
    this.navBarLogo = null;
  }
}

export class WhiteLabelContentApp {
  squareLogo: string;
  rectangleLogo: string;

  constructor() {
    this.squareLogo = null;
    this.rectangleLogo = null;
  }
}

export class TrialReason {
  orgId: number;
  reason: string;
  comment: string;
  date: Date;

  constructor(_orgId: number, _reason: string, _comment: string, _date: Date) {
    this.orgId = _orgId;
    this.reason = _reason;
    this.comment = _comment;
    this.date = _date;
  }
}

export class StripeDetailsPackage {
  customerId: string;
  productForm: StripeProductForm;
  paymentMethodId: string;

  constructor(_customerId: string, _productForm: StripeProductForm, _paymentMethodId: string) {
    this.customerId = _customerId;
    this.productForm = _productForm;
    this.paymentMethodId = _paymentMethodId;
  }
}

export class StorageLimit{
  item1: number;
  item2: number;
}

export class OrganisationUsageInfo {
  organisationName: string;
  subscriptionStatus: string;
  billingPeriodStart: Date;
  billingPeriodEnd: Date;
  isManual: boolean;
  isReseller: boolean;
  isMaster: boolean;
  isActive: boolean;
  canWhitelabel: boolean;
  creatorLicensesInUse: number;
  creatorLicenses: number;
  activeUsers: number;
  numberOfMicrosims: number;
  numberOfInteractives: number;
  numberOf360Videos: number;
  numberOf360Images: number;
  numberOf2DVideos: number;
  numberOf2DImages: number;
  numberOfAudios: number;
  numberOfPDFs: number;
  numberOfQuestions: number;
  numberOfPractices: number;
  numberOfLessonPlans: number;
  numberOfStandardLessons: number;
  numberOfSequencedLessons: number;
  numberOfSessionsScheduled: number;
  numberOfLiveSessionsScheduled: number;
  numberOfOnDemandSessionsScheduled: number;
  numberOfScormSessionsScheduled: number;
  numberOfSessionsAccessed: number;
  numberOfLiveSessionsAccessed: number;
  numberOfOnDemandSessionsAccessed: number;
  numberOfInteractiveLinks: number;
  numberOfMicrosimLinks: number;
  numberOfInteractiveLinkViews: number;
  numberOfMicrosimLinkViews: number;
  dataUsage: number;
  desktopEntries: number;
  picoEntries: number;
  htcEntries: number;
  metaEntries: number;
}

export class OrganisationEventsHistory {
  timePeriod: string;
  trialsStarted: number;
  subscriptionsStarted: number;
  usersSignedUp: number;
  sessionsCreated: number;
  sessionsOccured: number;
  microsimsCreated: number;
  interactivesCreated: number;
  video360Uploaded: number;
  image360Uploaded: number;
  audioUploaded: number;
  video2DUploaded: number;
  image2DUploaded: number;
  pdfUploaded: number;
  questionsCreated: number;
  desktopEntries: number;
  picoEntries: number;
  htcEntries: number;
  metaEntries: number;
}

export class PendingSignup {
  uid: string;
  email: string;
  displayName: string;
  employeeId: string;
  role: string;
  firstName: string;
  lastName: string;
  timeZone: string;
  hasCreatorLicense:boolean;
  organisationId: number;
  organisationName: string;
  subscriptionStatus: string;
  priceType: string;
  dataRegion: string;
  storageLimit: number;
  creatorLicenseLimit: number;
  isManualBilling: boolean;
  priceId: string;
  numOfUsers: number;
  resellerOrgStripeStatus: string;

}

export interface OrganisationSimple{
  organisationId: number;
  organisationName: string;
  superAdminEmail: string;
}