import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AccountService } from 'src/app/services/accounts/account.service';
import { Userpilot } from 'userpilot';

@Injectable({
	providedIn: 'root'
})
export class ResellerGuard implements CanActivate {

	path: ActivatedRouteSnapshot[];
	route: ActivatedRouteSnapshot;

	constructor(public accService: AccountService, private router: Router) { }

	async canActivate() {
		const currentOrg = await this.accService.getCurrentOrg();
		if(currentOrg && currentOrg.isResellerOrg)
		{
			Userpilot.reload();
			return true;
		}
		else
		{
			this.router.navigate(['/home']);//, { queryParams: { returnUrl: state.url } });
			return false;
		} 
	}
}
