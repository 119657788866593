import { Component, Input } from '@angular/core';
import { Alert, AlertType } from 'src/app/models/alert.model';
import { AlertService } from 'src/app/services/alert.service';
import { UserProfile } from 'src/app/models/userprofile.model';
import { AuthenticationService } from 'src/app/services/accounts/authentication.service';
import { Router } from '@angular/router';

@Component({
    selector: 'alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.css']
})
export class AlertComponent {
  @Input() id: string;

  alerts: Alert[] = [];
  timeoutTime:number = 9000;
  currentUser: UserProfile;

  constructor(private alertService: AlertService,private authService: AuthenticationService, private router: Router) { 
        this.router.events.subscribe(() => {
          this.currentUser = this.authService.CurrentUserProfile;
        });
  }

  ngOnInit() {
      this.alertService.getAlert(this.id).subscribe((alert: Alert) => {
          if (!alert.message) {
              // clear alerts when an empty alert is received
              this.alerts = [];
              return;
          }

          // add alert to array
          this.alerts.push(alert);
          setTimeout(() => this.removeAlert(alert), this.timeoutTime);
      });
  }

  removeAlert(alert: Alert) {
      this.alerts = this.alerts.filter(x => x !== alert);
  }
  

  cssClass(alert: Alert) {
      if (!alert) {
          return;
      }

      // return css class based on alert type
      switch (alert.type) {
          case AlertType.Success:
              return 'alert alert-success';
          case AlertType.Error:
              return 'alert alert-danger';
          case AlertType.Info:
              return 'alert alert-info';
          case AlertType.Warning:
              return 'alert alert-warning';
      }
  }
}
