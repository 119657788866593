export class Confirmation {
    message?: string;
    messages?: string[];
    multiMessage?: boolean = false;
    action: () => void;
    confirmationId: string;
    positive: boolean;
    acceptText: string;
    rejectText: string;
    inputOn?: boolean = false;
    inputTextToMatch?:string;
    showUndoneMessage?: boolean = false;
  
    constructor(init?:Partial<Confirmation>) {
        Object.assign(this, init);
    }
  }
  