import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/services/accounts/authentication.service';
import { Userpilot } from 'userpilot';

@Injectable({
	providedIn: 'root'
})
export class AdminGuard implements CanActivate, CanActivateChild {

	path: ActivatedRouteSnapshot[];
	route: ActivatedRouteSnapshot;

	constructor(public authService: AuthenticationService, private router: Router) { }

	canActivate() {
		const currentUser = this.authService.CurrentUserProfile;
		if(currentUser && (currentUser.role == "Admin" || currentUser.role == "Super Admin"))
		{
			Userpilot.reload();
			return true;
		}
		else
		{
			// not logged in so redirect to login page with the return url
			this.router.navigate(['/login']);//, { queryParams: { returnUrl: state.url } });
			return false;
		} 
	}

	canActivateChild(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	  ): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {
		const currentUser = this.authService.CurrentUserProfile;
		if(currentUser && (currentUser.role == "Admin" || currentUser.role == "Super Admin"))
		{
			var url = new URL(window.location.href).origin + state.url;
			Userpilot.reload({url: url});
			return true;
		}
		else
		{
			// not logged in so redirect to login page with the return url
			this.router.navigate(['/login']);//, { queryParams: { returnUrl: state.url } });
			return false;
		} 
	  }
}
