import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Group, GroupInfo } from '../models/group.model';
import { ErrorResponseHandler } from '../shared/error.handler';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { UserProfile } from '../models/userprofile.model';
import { VisibilityItem } from '../models/records.model';
import { AccountService } from './accounts/account.service';

@Injectable({
	providedIn: 'root'
})
export class GroupService {

	private endpoint: string;
	currentUser: UserProfile;
	private currentGroups: Group[] = [];
	private currentGroupsLoaded: boolean = false;

	constructor(private http: HttpClient) {
		this.endpoint = environment.endpoint;
		//this.currentUser = this.accService.currentUser;

	}

	public setCurrentGroups(orgId: number) {
		this.currentGroupsLoaded = false;
		this.getGroupsByOrgId(orgId).then((groups) => {
			this.currentGroups = groups;
			this.currentGroupsLoaded = true;
		});
	}

	public clearCurrentGroups() {
		this.currentGroups = [];
		this.currentGroupsLoaded = true;
	}

	public async getCurrentGroups(): Promise<Group[]> {
		while (!this.currentGroupsLoaded) // non-page blocking waiting loop
		{
			await new Promise(resolve => setTimeout(resolve, 250));
		}
		return this.currentGroups;
	}

	public createNewGroup(group: Group): Promise<any> {
		return this.http.post<any>(this.endpoint + '/API/Group', group,
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public getGroup(groupId: string, currentOrgId: number): Promise<Group> {
		if (groupId == currentOrgId.toString()) {
			return Promise.resolve({
				groupId: 'All',
				groupName: 'All Users',
				organisationId: parseInt(groupId),
				dateCreated: new Date(0, 0, 0, 0, 0, 0, 1),
				dateDeleted: new Date(0, 0, 0, 0, 0, 0, 0)
			})
		}
		else {
			return this.http.get<Group>(this.endpoint + '/API/Group/' + groupId,
				{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
				.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
		}
	}

	public getGroupInfo(groupId: string): Promise<GroupInfo> {
		return this.http.get<GroupInfo>(this.endpoint + '/API/Group/Users/' + groupId,
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public getGroupsByOrgId(orgId: number): Promise<Group[]> {
		return this.http.get<Group[]>(this.endpoint + '/API/Group/All/' + orgId,
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public getAllGroups(): Promise<Group[]> {
		return this.http.get<Group[]>(this.endpoint + '/API/Group',
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public updateGroup(group: Group): Promise<any> {
		return this.http.put<any>(this.endpoint + '/API/Group', group,
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public deleteGroup(groupId: string): Promise<any> {
		return this.http.delete<any>(this.endpoint + '/API/Group/Delete/' + groupId,
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public addUser(userId: string, groupId: string): Promise<any> {
		return this.http.put<any>(this.endpoint + '/API/UserProfile/AddToGroup', { "userId": userId, "groupId": groupId },
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public removeUser(userId: string, groupId: string): Promise<any> {
		return this.http.put<any>(this.endpoint + '/API/UserProfile/RemoveFromGroup', { "userId": userId, "groupId": groupId },
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	get sortOptions(): string[] {
		return ["name", "userCountMost", "userCountLeast"];
	}

	get sortOptionsDisplayName(): {} {
		return { "name": "Group Name", "userCountMost": "Number of Users (Most to Least)", "userCountLeast": "Number of Users (Least to Most)" };
	}

	public convertGroupToVisibilityItem(group: Group): VisibilityItem {
		return {
			id: group.groupId,
			name: group.groupName,
			selected: false
		}
	}

	public filterOptions(groups: Group[] = []): string[] {
		let options = ["", "public", "private"];
		if (groups && groups.length > 0) {
			groups.forEach(group => {
				options.push(group.groupId);
			});
		}
		return options;
	}

	public filterOptionsDisplayName(session: boolean = false, groups: Group[] = []): {} {
		let names = { "": "Any" }
		if (session) // sessions display names
		{
			names["public"] = "All Users";
			names["private"] = "None";
		}
		else // lesson plans display names
		{
			names["public"] = "Organisation Wide";
			names["private"] = "Only Me";
		}

		if (groups && groups.length > 0) {
			groups.forEach(group => {
				names[group.groupId] = group.groupName;
			});
		}
		return names;
	}

	public filterGroups(groups: Group[], options: {}, currentUser: UserProfile): Group[] {
		if (Object.keys(options).length > 0) {
			for (let [key, value] of Object.entries(options)) {
				switch (key) {
					case "name":
						if (value != "" && value != null) {
							groups = groups.filter(group =>
							(
								group.groupName.trim().toLowerCase().includes(value.toString().trim().toLowerCase())
							));
						}
						break;
					case "myGroups":
						if (value != "" && value != null) {
							groups = groups.filter(group => currentUser.groupIds.includes(group.groupId));
						}
						break;
				}
			}
		}
		return groups;
	}

	public sortGroups(groups: Group[], sortType: string): Group[] {
		switch (sortType) {
			case "name":
				groups.sort(function (a, b) {
					return (a.groupName.trim().toLowerCase() < b.groupName.trim().toLowerCase()) ? -1 : (a.groupName.trim().toLowerCase() > b.groupName.trim().toLowerCase()) ? 1 : 0;
				});
				break;
			case "userCountMost":
				// user count currently missing in data
				break;
			case "userCountLeast":
				// user count currently missing in data
				break;
		}

		return groups;
	}

	public filterToOwnGroups(groups: Group[], user: UserProfile): Group[] {
		return groups.filter(function (e) {
			return this.indexOf(e.groupId) >= 0;
		}, user.groupIds);
	}

	public sortedGroupIdsByName(groupIds: string[], groups: Group[], altPublicOrgId?: string) {
		return groupIds.map(id => {
			if (altPublicOrgId) {
				return this.getGroupName(id, groups, altPublicOrgId);
			}
			else {
				return this.getGroupName(id, groups);
			}
		}).sort(function (a, b) {
			return (a < b) ? -1 : (a > b) ? 1 : 0;
		});
	}

	public getGroupName(groupId: string, groups: Group[], altPublicOrgId?: string): string {
		if (groupId && groupId != "") {
			if ((altPublicOrgId && groupId == altPublicOrgId) || groupId == "All") {
				return "Organisation Wide";
			}
			else {
				let group = groups.find(x => x.groupId == groupId);
				if (group) {
					return group.groupName;
				}
				else {
					return "Unknown Group";
				}
			}
		}
		else {
			return "Only Me";
		}
	}
}
