import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { ErrorResponseHandler } from 'src/app/shared/error.handler';

import { NewCustomerRequest, CustomerResponse, StripeProduct, UpdateCustomerRequest, NewSubscriptionRequest, SubscriptionResponse, NewPaymentIntentRequest, TaxId } from 'src/app/models/stripe.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { of } from 'rxjs';
import { EmailValidator } from 'src/app/shared/validators/validators';
import { ActiveUsersCount, Organisation } from 'src/app/models/organisation.model';

@Injectable({
	providedIn: 'root'
})
export class StripeService {

	private endpoint: string;

	constructor(private http: HttpClient, private fb: FormBuilder)
	{
		this.endpoint = environment.endpoint;
	}

	get currencyNames() : {} {
		return {"aud":"Australian Dollar","usd":"US Dollar","eur":"Euro","gbp":"British Pound","nzd":"New Zealand Dollar"};
	}

	get currencyNamesShort() : {} {
		return {"aud":"AUD","usd":"USD","eur":"EUR","gbp":"GBP","nzd":"NZD"};
	}

	get tierNames()
	{
		return ["Starter","Mid","Growth","Large"];
	}

	get planList() : {}
	{
		return {'annual':["Select the minimum amount of experience participants you would like per month","Plans start from a minimum of 5 users","You are required to pre-pay your selected monthly experience participants for the year","If you exceed your minimum of monthly experience participants then you will be charged the excess for that month in a seperate, additional invoice","Please refer to our terms and conditions for more information","This Help Centre article also outlines the Annual Plan in more depth"],
				'monthly':["You are invoiced monthly","Charge varies based on the number of experience participants that month","Cost per user is dependent on the number of experience participants that month","Minimum charge of 5 users per month","Please refer to our terms and conditions for more information","This Help Centre article also outlines the Monthly Plan in more depth"]};
	}

	get helpList() : {}
	{
		 return {'annual':'/help/billing/subscriptions-annual',
		 'monthly': '/help/billing/subscriptions-monthly'};
	}

	public createCustomer(customerRequest: NewCustomerRequest) : Promise<CustomerResponse> {
		if(environment.envName == "production" || environment.envName == "test")
		{
			return this.http.post<CustomerResponse>(this.endpoint + '/api/Stripe/Customer/New', customerRequest,
			{headers:{'Content-Type': 'application/json; charset=utf-8'}})
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
		}
		else
		{
			return of(null).toPromise();
		}
	}

	public updateCustomer(customerRequest: UpdateCustomerRequest) : Promise<CustomerResponse> {
		if(environment.envName == "production" || environment.envName == "test")
		{
			return this.http.post<CustomerResponse>(this.endpoint + '/api/Stripe/Customer/Update', customerRequest,
			{headers:{'Content-Type': 'application/json; charset=utf-8'}})
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
		}
		else
		{
			return of(null).toPromise();
		}
	}

	public createSubscription(subscriptionRequest: NewSubscriptionRequest) : Promise<SubscriptionResponse> {
		if(environment.envName == "production" || environment.envName == "test")
		{
			return this.http.post<SubscriptionResponse>(this.endpoint + '/api/Stripe/Subscription/New', subscriptionRequest,
			{headers:{'Content-Type': 'application/json; charset=utf-8'}})
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
		}
		else
		{
			return of(null).toPromise();
		}
	}

	public changeSubscription(subscriptionRequest: NewSubscriptionRequest):Promise<SubscriptionResponse> {
		if(environment.envName == "production" || environment.envName == "test")
		{
			return this.http.put<SubscriptionResponse>(this.endpoint + '/api/Stripe/Subscription/Change', subscriptionRequest,
			{headers:{'Content-Type': 'application/json; charset=utf-8'}})
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
		}
		else
		{
			return of(null).toPromise();
		}
	}

	public cancelSubscription(subscriptionRequest: NewSubscriptionRequest):Promise<SubscriptionResponse>{
		if(environment.envName == "production" || environment.envName == "test")
		{
			return this.http.put<SubscriptionResponse>(this.endpoint + '/api/Stripe/Subscription/Cancel', subscriptionRequest,
			{headers:{'Content-Type': 'application/json; charset=utf-8'}})
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
		}
		else
		{
			return of(null).toPromise();
		}
	}

	public createPaymentIntent(intentRequest: NewPaymentIntentRequest) : Promise<any> {
		if(environment.envName == "production" || environment.envName == "test")
		{
			return this.http.post<any>(this.endpoint + '/api/Stripe/Payment/Intent', intentRequest,
			{headers:{'Content-Type': 'application/json; charset=utf-8'}})
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
		}
		else
		{
			return of(null).toPromise();
		}
	}

	public getProduct(productId: string) : Promise<StripeProduct> {
		if(environment.envName == "production" || environment.envName == "test")
		{
			return this.http.get<StripeProduct>(this.endpoint + '/api/Stripe/Product/' + productId,
			{headers:{'Content-Type': 'application/json; charset=utf-8'}})
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
		}
		else
		{
			return of(null).toPromise();
		}
	}

	public getProductByPriceId(priceId: string) : Promise<StripeProduct> {
		if(environment.envName == "production" || environment.envName == "test")
		{
			return this.http.get<StripeProduct>(this.endpoint + '/api/Stripe/Product/ByPrice/' + priceId,
			{headers:{'Content-Type': 'application/json; charset=utf-8'}})
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
		}
		else
		{
			return of(null).toPromise();
		}
	}

	public getProducts() : Promise<StripeProduct[]> {
		if(environment.envName == "production" || environment.envName == "test")
		{
			return this.http.get<StripeProduct[]>(this.endpoint + '/api/Stripe/Products',
			{headers:{'Content-Type': 'application/json; charset=utf-8'}})
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
		}
		else
		{
			return of([]).toPromise();
		}
	}

	public getPortalLink(customerId: string) : Promise<string> {
		if(environment.envName == "production" || environment.envName == "test")
		{
			return this.http.get<string>(this.endpoint + '/api/Stripe/Customer/PortalLink/' + customerId,
			{headers:{'Content-Type': 'application/json; charset=utf-8'}})
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
		}
		else
		{
			return of("").toPromise();
		}
	}

	public getConnectSignupLink(organisationId: number) : Promise<string> {
		if(environment.envName == "production" || environment.envName == "test")
		{
			return this.http.get<string>(this.endpoint + '/api/Stripe/Customer/Connect/Signup/' + organisationId,
			{headers:{'Content-Type': 'application/json; charset=utf-8'}})
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
		}
		else
		{
			return of("").toPromise();
		}
	}

	// public getResellerAccount(resellerOrgStripeId) : Promise<any> {
	// 	if(environment.envName == "production" || environment.envName == "test")
	// 	{
	// 		return this.http.get<string>(this.endpoint + '/api/Stripe/Customer/Connect/' + resellerOrgStripeId,
	// 		{headers:{'Content-Type': 'application/json; charset=utf-8'}})
	// 		.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	// 	}
	// 	else
	// 	{
	// 		return of(null).toPromise();
	// 	}
	// }

	public getResellerPercentage(resellerOrgId: number) : Promise<number> {
		if(environment.envName == "production" || environment.envName == "test")
		{
			return this.http.get<number>(this.endpoint + '/api/Stripe/ResellerPercentage/' + resellerOrgId,
			{headers:{'Content-Type': 'application/json; charset=utf-8'}})
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
		}
		else
		{
			return of(0).toPromise();
		}
	}

	public getCustomer(customerId: string) : Promise<any> {
		if(environment.envName == "production" || environment.envName == "test")
		{
			return this.http.get<any>(this.endpoint + '/api/Stripe/Customer/' + customerId,
			{headers:{'Content-Type': 'application/json; charset=utf-8'}})
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
		}
		else
		{
			return of(null).toPromise();
		}
	}

	public getExistingPaymentMethods(customerId: string) : Promise<any[]> {
		if(environment.envName == "production" || environment.envName == "test")
		{
			return this.http.get<any[]>(this.endpoint + '/api/Stripe/Customer/PaymentMethods/' + customerId,
			{headers:{'Content-Type': 'application/json; charset=utf-8'}})
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();}
		else
		{
			return of([]).toPromise();
		}
	}

	public getPrice(priceId: string) : Promise<any> {
		if(environment.envName == "production" || environment.envName == "test")
		{
			return this.http.get<any>(this.endpoint + '/api/Stripe/Price/' + priceId,
			{headers:{'Content-Type': 'application/json; charset=utf-8'}})
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
		}
		else
		{
			return of(null).toPromise();
		}
	}

	//you can preview the upcoming invoice for a customer. This will show you all the charges that are pending, 
	//including subscription renewal charges, invoice item charges, etc. It will also show you any discounts that
	// are applicable to the invoice 
	public getUpcomingInvoice(customerId: string) : Promise<any> {
		if(environment.envName == "production" || environment.envName == "test")
		{
			return this.http.get<any>(this.endpoint + '/api/Stripe/Invoice/' + customerId,
			{headers:{'Content-Type': 'application/json; charset=utf-8'}})
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
		}
		else
		{
			return of(null).toPromise();
		}
	}

	public getUpcomingInvoiceReseller(subscriptionId: string, customerId: string) : Promise<any> {
		if(environment.envName == "production" || environment.envName == "test")
		{
			return this.http.get<any>(this.endpoint + '/api/Stripe/InvoiceReseller/' + subscriptionId + "," + customerId,
			{headers:{'Content-Type': 'application/json; charset=utf-8'}})
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
		}
		else
		{
			return of(null).toPromise();
		}
	}

	public getScheduledSubscription(customerId: string) : Promise<any> {
		if(environment.envName == "production" || environment.envName == "test")
		{
			return this.http.get<any>(this.endpoint + '/api/Stripe/Scheduled/' + customerId,
			{headers:{'Content-Type': 'application/json; charset=utf-8'}})
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
		}
		else
		{
			return of(null).toPromise();
		}
	}

	public getCoupon(couponId: string) : Promise<any> {
		if(environment.envName == "production" || environment.envName == "test")
		{
			return this.http.get<any>(this.endpoint + '/api/Stripe/Coupon/' + couponId,
			{headers:{'Content-Type': 'application/json; charset=utf-8'}})
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
		}
		else
		{
			return of(null).toPromise();
		}
	}

	// tax ids //

	public addTaxId(customerId: string, taxId: TaxId) : Promise<any> {
		if(environment.envName == "production" || environment.envName == "test")
		{
			return this.http.post<any>(this.endpoint + '/api/Stripe/Customer/TaxId/' + customerId, taxId,
			{headers:{'Content-Type': 'application/json; charset=utf-8'}})
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();}
		else
		{
			return of(null).toPromise();
		}
	}

	public getTaxId(customerId: string) : Promise<any> {
		if(environment.envName == "production" || environment.envName == "test")
		{
			return this.http.get<any>(this.endpoint + '/api/Stripe/Customer/TaxId/' + customerId,
			{headers:{'Content-Type': 'application/json; charset=utf-8'}})
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();}
		else
		{
			return of(null).toPromise();
		}
	}

	public deleteTaxId(customerId: string) : Promise<any> {
		if(environment.envName == "production" || environment.envName == "test")
		{
			return this.http.delete<any>(this.endpoint + '/api/Stripe/Customer/TaxId/' + customerId,
			{headers:{'Content-Type': 'application/json; charset=utf-8'}})
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();}
		else
		{
			return of(null).toPromise();
		}
	}

	// forms //

	public productForm() : FormGroup {
		return this.fb.group({
			currency: ['',Validators.required],
			priceId: ['',Validators.required],
			numOfUsers: [0,Validators.required],
			creatorLicenses: [0]
		});	
	}

	public customerForm() : FormGroup {
		return this.fb.group({
			orgId: [''],
			customerId: [''],
			email: ['',[Validators.required, Validators.email, EmailValidator]],
			name: ['',[Validators.required]],
			orgName: ['',[Validators.required]],
			phone: [''],
			city: [''],
			country: ['',[Validators.required]],
			line1: [''],
			line2: [''],
			postalCode: [''],
			state: [''],
			POnum: [''],
			taxId: [''],
			taxValue: ['']
		});	
	}

	// calculation help //
	public calculateTierIndex(numOfUsers: number, product: StripeProduct, activeUsersCount: ActiveUsersCount): number {
		let tierIndex: number = 0;
		switch(product.repayType)
		{
			case "annual":
				while(numOfUsers > product.bracketLimits[tierIndex])
				{
					tierIndex++;
				}
				break;
			case "monthly":
				while(activeUsersCount.activeUsers > activeUsersCount.bracketLimits[tierIndex])
				{
					tierIndex++;
				}
				break;
		}

		return tierIndex;
	}

	public calculateNextInvoice(numOfUsers: number, autoRenew: boolean, product: StripeProduct, activeUsersCount: ActiveUsersCount, price: any, discount: number, additional_price:any=null, creatorLicense: number, priceType: string): 
	{subInvoiceText: string, nextInvoiceText: string, additionalUserCost: string, combinedNextInvoiceText: string} {
		// get next invoice estimate
		var invoiceCost = 0;
		let additionalUserCost = 0;
		var clPrice = 0;
		let CLnum = creatorLicense; //num of CL
		let pricetype = priceType;

		let creatorLicensePricesMonthly = {"aud":140,"usd":95,"eur":95,"gbp":80,"nzd":160};
		let creatorLicensePricesMonthlyEdu = {"aud":100,"usd":65,"eur":65,"gbp":55,"nzd":110};
		let creatorLicensePricesAnnual = { "aud": 3000, "usd": 2500, "eur": 2300, "gbp": 2000, "nzd": 3300 };
		let creatorLicensePricesAnnualEdu = { "aud": 2100, "usd": 1750, "eur": 1600, "gbp": 980, "nzd": 2300 };

		//let tierIndex = this.calculateTierIndex(numOfUsers, product, activeUsersCount);
		let tierIndex = 0; //this.stripeService.calculateTierIndex(org.numOfUsers, product, activeUsers);
				switch(numOfUsers){
					case 50:
						tierIndex = 1;
					break;
					case 100:
						tierIndex = 2;
					break;
					case 200:
						tierIndex = 2;
					break;
					case 250:
						tierIndex = 4;
					break;
				}
		let subCost = price.tiers[tierIndex].unit_amount * numOfUsers;

		switch(product.repayType)
		{
			case "annual":

				if(pricetype != 'standard') {

					var amount = creatorLicensePricesAnnualEdu[price.currency];

					clPrice = CLnum * amount;
				}
				else {

					var amount = creatorLicensePricesAnnual[price.currency];

					clPrice = CLnum * amount;
					
				}
				additionalUserCost = additional_price.unit_amount;
				invoiceCost = additionalUserCost * Math.max(0, activeUsersCount.activeUsers - numOfUsers);

				if(!autoRenew)
				{
					subCost = 0;
				}
				break;
			case "monthly":

			if(pricetype != 'standard') {

				var amount = creatorLicensePricesMonthlyEdu[price.currency];

				clPrice = CLnum * amount;
			}
			else {

				var amount = creatorLicensePricesMonthly[price.currency];

				clPrice = CLnum * amount;
			}
				additionalUserCost = price.tiers[tierIndex].unit_amount;
				invoiceCost = additionalUserCost * activeUsersCount.activeUsers;
				break;
		}

		var totalcost = invoiceCost + subCost + (clPrice*100);
		if(discount > 0)
		{
			// subCost -= subCost * discount/100;
			// invoiceCost = invoiceCost * discount/100;
			// additionalUserCost -= additionalUserCost * discount/100;
			totalcost -= totalcost * discount/100;
		}

		//console.log(this.priceDisplay(totalcost, price.currency));

		return {subInvoiceText: this.priceDisplay(subCost, price.currency), nextInvoiceText: this.priceDisplay(invoiceCost, price.currency), additionalUserCost: this.priceDisplay(additionalUserCost, price.currency), combinedNextInvoiceText: this.priceDisplay(totalcost, price.currency)};
	}

	public isSubscriptionChangeValid(org:Organisation,currentProduct:StripeProduct,newProduct:StripeProduct, newUserCount:number):
	{isValid:boolean,errorMessage:string} {
		let isValid = true;
		let errorMessage = "";
		if(currentProduct.repayType=="annual"&&newProduct.repayType=="annual"&& org.numOfUsers>=newUserCount)
		{
			isValid=false;
			errorMessage="Annual subscriptions can only be changed to a higher tier";
		}
		if(newProduct.repayType == "annual"&&newProduct.minUsers>newUserCount)
		{
			isValid=false;
			errorMessage = "Number of users is below the minimum for subscription.";
		}
		return {isValid:isValid,errorMessage:errorMessage};
	}

	public priceDisplay(amount: number, currency: string): string {
		let sign = "";
		let value = (amount/100).toLocaleString('en-US', {minimumFractionDigits:2, maximumFractionDigits:2});
		let text = "";
		switch(currency)
		{
			case "aud":
			case "nzd":
			case "usd":
				sign = "$";
				break;
			case "eur":
				sign = "€";
				break;
			case "gbp":
				sign = "£";
				break;
		}
		if(sign == "$")
		{
			text = " "+currency.toUpperCase();
		}
		return sign+value+text;
	}
}
