import { Injectable } from '@angular/core';
import { Section, Article, NewsItem } from '../models/help.model';

@Injectable({
	providedIn: 'root'
})

export class HelpContentService {

	public SECTIONS: Section[] = [
		// link must be a unique string to all other sections
		{ section: "", link: "get-started", image: "help-get-started", title: "Get Started", description: "How to get started with Facilitate", loginReq: true, traineeGuard: true, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, page: false },
		// {section: "", link: "reseller", image: "https://facilitatestorage.blob.core.windows.net/$web/Billing.png", title: "Reseller Help" , description: "Everything regarding being a Facilitate Reseller", loginReq: true, traineeGuard: true, facilitatorGuard: true, masterOrgGuard: false, resellerGuard: true, page: false},
		{ section: "", link: "troubleshooting", image: "https://facilitatestorage.blob.core.windows.net/$web/Guides.png", title: "Troubleshooting", description: "Have run into an issue? Check our documentation for troubleshooting common issues.", loginReq: true, traineeGuard: true, facilitatorGuard: true, masterOrgGuard: false, resellerGuard: false, page: false },
		{ section: "", link: "Facilitate-Overview", image: "help-apps", title: "Facilitate Overview", description: "High-level overview of Facilitate", loginReq: false, traineeGuard: false, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, page: false },
		// { section: "", link: "Session-Menu", image: "https://facilitatestorage.blob.core.windows.net/$web/help-session-menu-icon.png", title: "Session Menu", description: "The Menu in your Facilitate session", loginReq: false, traineeGuard: false, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, page: false },
		// { section: "", link: "present", image: "https://facilitatestorage.blob.core.windows.net/$web/IconPresentHelp.png", title: "Standard and Immersive Content", description: "Everything to do with Standard and Immersive Content", loginReq: true, traineeGuard: true, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, page: false },
		{ section: "", link: "interactive-editor", image: "help-interactive", title: "360 Scenario Editor", description: "All help articles for the 360 editor and 360 Assets", loginReq: true, traineeGuard: true, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, page: false },
		{ section: "", link: "microsim-editor", image: "help-mse", title: "Micro-simulation Editor", description: "All help articles for the Micro-simulation editor", loginReq: true, traineeGuard: true, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, page: false },
		{ section: "", link: "fac-apps", image: "help-apps", title: "Facilitate Apps", description: "All help articles for the Facilitate VR and Desktop app", loginReq: false, traineeGuard: false, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, page: false },

		// {section: "present", link: "interactive-editor-videos", image: "https://facilitatestorage.blob.core.windows.net/$web/360Interactive.png", title: "Using the Facilitate Hotspot Creator - Video Guides" , description: "Video tutorials on how to features in the 360 Interactive Hotspot Creator", loginReq: true, traineeGuard: true, facilitatorGuard: false, masterOrgGuard: false, page: false},
		// {section: "", link: "practice", image: "https://facilitatestorage.blob.core.windows.net/$web/IconPracticeHelp.png", title: "Practice" , description: "Everything to do with practice", loginReq: true, traineeGuard: false, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, page: false},
		// { section: "", link: "assess", image: "https://facilitatestorage.blob.core.windows.net/$web/IconAssessHelp.png", title: "Assess", description: "Everything to do with assess", loginReq: true, traineeGuard: false, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, page: false },
		//{ section: "", link: "billing", image: "billing", title: "Billing", description: "Everything to do with Facilitate subscriptions and billing", loginReq: false, traineeGuard: false, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, page: false },
		// { section: "", link: "offline-sessions", image: "https://facilitatestorage.blob.core.windows.net/$web/Guides.png", title: "Offline Sessions", description: "Help aricles for Facilitate's offline sessions", loginReq: true, traineeGuard: false, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, page: false },
		{ section: "", link: "best-practices", image: "help-check", title: "Best Practices for Virtual Learning", description: "Get the most out of your virtual learning sessions", loginReq: false, traineeGuard: false, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, page: false },
		{ section: "", link: "hardware", image: "headset", title: "Hardware and Software", description: "VR headset and Desktop information", loginReq: false, traineeGuard: false, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, page: false },
		{ section: "hardware", link: "meta-headsets", image: "headset", title: "Meta Headsets", description: "Meta Quests help articles", loginReq: false, traineeGuard: false, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, page: false },
		{ section: "hardware", link: "vive-headsets", image: "headset", title: "Vive Headsets", description: "Vive headsets help articles", loginReq: false, traineeGuard: false, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, page: false },
		{ section: "hardware", link: "pico-headsets", image: "headset", title: "Pico Headsets", description: "Pico headsets help articles", loginReq: false, traineeGuard: false, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, page: false },
		{ section: "hardware", link: "downloads", image: "headset", title: "Downloading the Facilitate apps", description: "Quick links for getting the Facilitate apps", loginReq: false, traineeGuard: false, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, page: false },
		{ section: "fac-apps", link: "fac-vr-app", image: "headset", title: "Facilitate VR App", description: "All help articles for the Facilitate VR app", loginReq: false, traineeGuard: false, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, page: false },
		{ section: "fac-apps", link: "fac-desktop", image: "windows", title: "Facilitate Desktop App", description: "All help articles for the Facilitate desktop app", loginReq: false, traineeGuard: false, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, page: false },
		// { section: "", link: "downloads", image: "https://facilitatestorage.blob.core.windows.net/$web/Download-icon.png", title: "Downloads", description: "Get the Facilitate Desktop and VR apps", loginReq: false, traineeGuard: false, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, page: true },
		// { section: "", link: "documents", image: "https://facilitatestorage.blob.core.windows.net/$web/IconLessonPage.png", title: "Documents", description: "Technical Documents to help your Admin/IT integrate further with Facilitate", loginReq: true, traineeGuard: true, facilitatorGuard: true, masterOrgGuard: false, resellerGuard: false, page: true },
		// { section: "", link: "contact-us", image: "https://facilitatestorage.blob.core.windows.net/$web/Contact.png", title: "Contact Us", description: "Contact us if you have any questions, requests, issues or general thoughts", loginReq: true, traineeGuard: false, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, page: true } // specific message for trainees inside instead, allow access
	];

	public ARTICLES: Article[] = [
		// link must be a unique string to all other articles
		{ section: "troubleshooting", link: "vr-app", image: "headset", title: "Facilitate VR app", description: "Have run into an issue with our VR apps? Check our documentation for troubleshooting common issues.", loginReq: true, traineeGuard: false, facilitatorGuard: true, masterOrgGuard: false, resellerGuard: false, keywords: [] },
		{ section: "troubleshooting", link: "desktop-app", image: "windows", title: "Facilitate Desktop app", description: "Have run into an issue with our desktop app? Check our documentation for troubleshooting common issues.", loginReq: true, traineeGuard: false, facilitatorGuard: true, masterOrgGuard: false, resellerGuard: false, keywords: [] },
		{ section: "troubleshooting", link: "portal", image: "help-apps", title: "Facilitate Portal", description: "Have run into an issue with our Portal? Check our documentation for troubleshooting common issues.", loginReq: true, traineeGuard: false, facilitatorGuard: true, masterOrgGuard: false, resellerGuard: false, keywords: [] },
		
		{ section: "reseller", link: "billing-manager", image: "https://facilitatestorage.blob.core.windows.net/$web/help-session-menu-icon.png", title: "Managing Subscriptions", description: "How to invite and manage other organisations", loginReq: true, traineeGuard: true, facilitatorGuard: true, masterOrgGuard: false, resellerGuard: true, keywords: ["reseller", "manage", "subscription", "cancel", "invite"] },
		{ section: "reseller", link: "paymentdetails", image: "https://facilitatestorage.blob.core.windows.net/$web/Billing.png", title: "Managing Your Payment Details", description: "How to keep your own information updated", loginReq: true, traineeGuard: true, facilitatorGuard: true, masterOrgGuard: false, resellerGuard: true, keywords: ["reseller", "manage", "subscription", "cancel", "invite"] },
		{ section: "reseller", link: "resaletypes", image: "https://facilitatestorage.blob.core.windows.net/$web/Guides.png", title: "Discounts and Revenue Sharing", description: "How it's calculated, who pays what, how it changes", loginReq: true, traineeGuard: true, facilitatorGuard: true, masterOrgGuard: false, resellerGuard: true, keywords: ["reseller", "manage", "subscription", "cancel", "discount", "revenue", "share"] },
		
		{ section: "get-started", link: "assetlibrary", image: "library", title: "The Facilitate Library", description: "How to get started with the asset library", loginReq: true, traineeGuard: true, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, keywords: ["library", "upload", "create"] },
		{ section: "get-started", link: "tutorials", image: "help-tutorials", title: "Tutorials", description: "Explore our range of interactive tutorials to get you started with Facilitate", loginReq: true, traineeGuard: true, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, keywords: ["sequenced", "standard", "create", "experience", "tutorial", "sessions"] },
		{ section: "get-started", link: "create-experience", image: "experience", title: "Experiences", description: "How to get started with creating Experiences", loginReq: true, traineeGuard: true, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, keywords: ["sequenced", "standard", "create", "experience"] },
		{ section: "get-started", link: "schedule-session", image: "sessions", title: "Sessions", description: "How to get started with Facilitate sessions", loginReq: true, traineeGuard: true, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, keywords: ["sequenced", "standard", "scheduler", "invite", "flexible"] },
		{ section: "get-started", link: "facilitate-session", image: "review", title: "Facilitate and Review", description: "How to run your first Facilitate session and review your results", loginReq: true, traineeGuard: true, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, keywords: ["sequenced", "standard", "vr", "headset"] },
		{ section: "get-started", link: "managing-org", image: "manage", title: "Managing your organisation", description: "Inviting users to the Facilitate platform and managing groups", loginReq: true, traineeGuard: true, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, keywords: ["manager", "groups", "accounts", "users", "manager", "invite"] },
		
		{ section: "meta-headsets", link: "oculusquestheadset", image: "headset", title: "Meta Quest", description: "How the Meta Quest headset works", loginReq: false, traineeGuard: false, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, keywords: ["oculus", "quest", "headset", "VR"] },
		{ section: "meta-headsets", link: "oculusquest2headset", image: "headset", title: "Meta Quest 2", description: "How the Meta Quest 2 headset works", loginReq: false, traineeGuard: false, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, keywords: ["oculus", "quest", "headset", "VR"] },
		{ section: "vive-headsets", link: "vivefocusplusheadset", image: "headset", title: "Vive Focus Plus", description: "How the Vive Focus Plus headset works", loginReq: false, traineeGuard: false, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, keywords: ["htc", "vive", "headset", "VR"] },
		{ section: "vive-headsets", link: "focus3help", image: "headset", title: "Vive Focus 3", description: "How the Vive Focus 3 headset works", loginReq: false, traineeGuard: false, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, keywords: ["htc", "vive", "headset", "VR"] },
		{ section: "pico-headsets", link: "piconeo3help", image: "headset", title: "Pico Neo 3", description: "How the Pico Neo 3 headset works", loginReq: false, traineeGuard: false, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, keywords: ["neo 3", "pico", "headset", "VR"] },
		
		//{ section: "present", link: "presenting-in-vr", image: "https://facilitatestorage.blob.core.windows.net/$web/IconPresentHelp.png", title: "Presenting in VR", description: "Best practices and controls for VR presentation", loginReq: true, traineeGuard: true, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, keywords: ["marker", "presentation", "headset", "VR", "content"] },
		//{ section: "present", link: "presenting-in-desktop", image: "https://facilitatestorage.blob.core.windows.net/$web/IconPresentHelp.png", title: "Presenting on the Desktop", description: "Best practices and controls for Desktop presentation", loginReq: true, traineeGuard: true, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, keywords: ["marker", "presentation", "Desktop", "content"] },
		
		{ section: "interactive-editor", link: "360-tutorial-video", image: "help-interactive", title: "360 Editor Tutorial", description: "Watch this tutorial video to learn more about using the 360 Scenario Editor", loginReq: true, traineeGuard: true, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, keywords: ["360", "interactive", "hotspot", "creator", "editor", "create", "edit", "tutorial"] },
		{ section: "interactive-editor", link: "filming360", image: "help-interactive", title: "Filming and Uploading 360 Videos", description: "Best practices for filming and uploading Immersive videos", loginReq: true, traineeGuard: true, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, keywords: ["360", "monoscopic", "stereoscopic", "garmin", "video", "immersive"] },
		{ section: "interactive-editor", link: "browser-share", image: "help-interactive", title: "Publishing Immersive Assets", description: "How to generate a share link for your creations", loginReq: true, traineeGuard: true, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, keywords: ["360", "share", "link", "generate link", "publish", "immersive", "micro-simulation", "micro-sim"] },
		{ section: "interactive-editor", link: "optimising360video", image: "help-interactive", title: "Optimising your 360 Videos", description: "Best settings for optimising and encoding Immersive videos", loginReq: true, traineeGuard: true, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, keywords: ["360", "video", "2D", "optimisation", "encoding", "resolution", "standard", "immersive"] },
		
		{ section: "microsim-editor", link: "microsim-video-basics", image: "help-mse", title: "Micro-simulation Tutorial", description: "Watch this tutorial video to learn more about using the Micro-simulation Editor", loginReq: true, traineeGuard: true, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, keywords: ["create", "simulation", "micro", "editor", "creator", "tutorial"] },
		{ section: "microsim-editor", link: "microsim-editor-basics", image: "help-mse", title: "The Basics", description: "Getting started with the Micro-simulation Editor", loginReq: true, traineeGuard: true, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, keywords: ["create", "simulation", "micro", "editor", "creator"] },
		{ section: "microsim-editor", link: "microsims-actions", image: "help-mse", title: "Adding actions", description: "Everything you need to know around the Action Panel", loginReq: true, traineeGuard: true, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, keywords: ["create", "simulation", "micro", "editor", "creator"] },
		{ section: "microsim-editor", link: "browser-share", image: "help-mse", title: "Publishing Immersive Assets", description: "How to generate a share link for your creations", loginReq: true, traineeGuard: true, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, keywords: ["360", "share", "link", "generate link", "publish", "immersive", "micro-simulation", "micro-sim"] },
		{ section: "microsim-editor", link: "microsim-best-practice", image: "help-mse", title: "Formatting 3D models for Facilitate", description: "How to optimise your 3D files for the Micro-sim Editor", loginReq: true, traineeGuard: true, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, keywords: ["create", "simulation", "micro", "editor", "creator"] },
		{ section: "microsim-editor", link: "microsim-teleportation", image: "help-mse", title: "Teleporting in Micro-simulations", description: "How to move and look around ", loginReq: true, traineeGuard: false, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, keywords: ["teleport", "teleportation", "move around", "move", "simulation", "micro", "editor", "creator"] },



		{ section: "assess", link: "assess-in-vr", image: "https://facilitatestorage.blob.core.windows.net/$web/IconAssessHelp.png", title: "Assess in VR", description: "How to use the assess feature", loginReq: true, traineeGuard: false, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, keywords: ["results"] },
		{ section: "assess", link: "assess-in-desktop", image: "https://facilitatestorage.blob.core.windows.net/$web/IconAssessHelp.png", title: "Assess on the Desktop", description: "How to use the assess feature", loginReq: true, traineeGuard: false, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, keywords: ["results"] },
		{ section: "assess", link: "assess-vr-trainees", image: "https://facilitatestorage.blob.core.windows.net/$web/IconAssessHelp.png", title: "Assessment in VR for Learners", description: "How to answer questions in VR", loginReq: false, traineeGuard: false, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, keywords: ["learner", "guests"] },
	
		{ section: "Facilitate-Overview", link: "signin", image: "help-sign-in", title: "Signing In", description: "How to sign into the Facilitate app", loginReq: false, traineeGuard: false, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, keywords: ["live", "open", "on-demand", "solo", "collab", "collaborative", "sequenced", "standard", "experience", "guest", "guest access"] },
		{ section: "Facilitate-Overview", link: "export-experience", image: "help-scorm", title: "SCORM Export", description: "How to export your experiences as a SCORM package", loginReq: true, traineeGuard: true, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, keywords: ["SCORM", "scorm", "lms", "export", "experience"] },
		{ section: "Facilitate-Overview", link: "formatpdf", image: "help-pdf", title: "Formatting a PDF for Facilitate", description: "Best practice for PDF presentations for Facilitate", loginReq: true, traineeGuard: true, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, keywords: ["font size", "font", "powerpoint"] },
		//{ section: "Facilitate-Overview", link: "checkcontent", image: "help-check", title: "Checking your Content", description: "How to view your Experience in the Facilitate App before your session", loginReq: true, traineeGuard: true, facilitatorGuard: false, resellerGuard: false, masterOrgGuard: false, keywords: ["check", "upcoming", "test", "present", "content"] },
		{ section: "Facilitate-Overview", link: "assess-results", image: "review", title: "Finding your Learners' assessment results", description: "How to find your trainees' assessment results", loginReq: true, traineeGuard: true, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, keywords: ["results"] },
		{ section: "Facilitate-Overview", link: "view-your-results", image: "review", title: "Find your assessment results", description: "How to find your assessment results", loginReq: true, traineeGuard: false, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, keywords: ["results"] },
		{ section: "Facilitate-Overview", link: "no-training-space", image: "sessions", title: "Direct content access", description: "how to publish immersive assets without a training space", loginReq: true, traineeGuard: true, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, keywords: ["results"] },
		{ section: "Facilitate-Overview", link: "permissionlevel", image: "new-org-manage", title: "Permission Levels", description: "Breakdown of the Facilitate Permission Levels", loginReq: true, traineeGuard: false, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, keywords: ["Permission", "admin", "creator", "learner", "guest", "guest access"] },
		{ section: "Facilitate-Overview", link: "sessiontypes", image: "sessions", title: "Session Types", description: "Breakdown of the different Facilitate Session types", loginReq: true, traineeGuard: false, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, keywords: ["live", "open", "on-demand", "solo", "sequenced", "standard", "experience", "scheduler"] },
		{ section: "Facilitate-Overview", link: "masterorg", image: "new-org-manage", title: "Master Organisations", description: "Everything you need to know around Master organisations", loginReq: true, traineeGuard: true, facilitatorGuard: false, masterOrgGuard: true, resellerGuard: false, keywords: ["visibility", "share", "share content", "sequenced", "standard", "experience", "sub-organisation", "master"] },
		
		//{ section: "billing", link: "subscriptions", image: "billing", title: "Facilitate subscriptions", description: "High level overview of how Facilitate billing works", loginReq: false, traineeGuard: false, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, keywords: ["money", "subscription", "subscriptions", "annual", "monthly", "billing", "price", "pricing", "invoice", "plan"] },
		//{ section: "billing", link: "subscriptions-monthly", image: "billing", title: "Monthly subscriptions", description: "How Facilitate’s monthly subscription works", loginReq: false, traineeGuard: false, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, keywords: ["money", "subscription", "subscriptions", "annual", "monthly", "billing", "price", "pricing", "invoice", "plan", "faq"] },
		//{ section: "billing", link: "subscriptions-annual", image: "billing", title: "Annual subscriptions", description: "How Facilitate’s annual subscription works", loginReq: false, traineeGuard: false, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, keywords: ["money", "subscription", "subscriptions", "annual", "monthly", "billing", "price", "pricing", "invoice", "plan", "faq"] },
		{ section: "", link: "desktop-overview", image: "windows", title: "The Facilitate Desktop App", description: "Breakdown of the Facilitate desktop app", loginReq: true, traineeGuard: false, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, keywords: ["live", "open", "on-demand", "solo", "sequenced", "standard", "join", "download", "logout", "desktop"] },
		
		{ section: "fac-desktop", link: "facilitator-desktop-menu", image: "windows", title: "The Facilitator Desktop Menu", description: "Breakdown of the Facilitator Menu for Desktop Collaborative Sessions", loginReq: false, traineeGuard: false, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, keywords: ["live", "open", "facilitator", "menu", "session", "content", "experience"] },
		{ section: "fac-desktop", link: "trainee-menu-desktop", image: "windows", title: "The Learner Collaborative Menu", description: "How to use the desktop learner menu for Collaborative sessions", loginReq: false, traineeGuard: false, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, keywords: ["live", "open", "learner", "menu", "session", "content", "experience", "guest"] },
		{ section: "fac-desktop", link: "on-demand-menu", image: "windows", title: "The Solo Menu", description: "How to use the desktop Solo Menu", loginReq: false, traineeGuard: false, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, keywords: ["on-demand", "open", "learner", "menu", "session", "content", "experience", "guest"] },

		{ section: "fac-vr-app", link: "titlemenu", image: "headset", title: "The VR Title Menu", description: "Breakdown of the Facilitate title menu", loginReq: true, traineeGuard: false, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, keywords: ["live", "open", "solo", "collab", "collaborative","on-demand", "sequenced", "standard", "join", "download", "logout"] },
		{ section: "fac-vr-app", link: "facilitator-vr-menu-seq", image: "headset", title: "The Facilitator Menu", description: "How to use the Facilitator VR menu", loginReq: false, traineeGuard: false, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, keywords: ["live", "open", "facilitator", "menu", "session", "content", "experience", "sequenced"] },
		{ section: "fac-vr-app", link: "trainee-menu", image: "headset", title: "The Trainee Collab Menu", description: "How to use the VR trainee menu for collaborative sessions", loginReq: false, traineeGuard: false, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, keywords: ["live", "open", "learner", "menu", "session", "content", "experience", "guest"] },
		{ section: "fac-vr-app", link: "on-demand-menu-vr", image: "headset", title: "The Solo Menu", description: "How to use the VR Solo Menu", loginReq: false, traineeGuard: false, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, keywords: ["on-demand", "solo", "collab", "collaborative", "open", "learner", "menu", "session", "content", "experience", "guest"] },
		{ section: "fac-vr-app", link: "session-controls", image: "headset", title: "The VR Session controls", description: "Breakdown of general controls in Facilitate VR", loginReq: false, traineeGuard: false, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, keywords: ["live", "open", "controls", "avatars", "move chairs"] },
		{ section: "fac-vr-app", link: "preparing-content", image: "headset", title: "Downloading large sessions onto your device", description: "Best practice for downloading large files onto the Facilitate apps", loginReq: true, traineeGuard: true, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, keywords: ["sequenced", "standard", "scheduler", "offline", "flexible", "download", "large", "time", "session"] },
		{ section: "fac-vr-app", link: "offline-sessions", image: "headset", title: "Offline VR Sessions", description: "How offline VR sessions work", loginReq: true, traineeGuard: false, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, keywords: ["on-demand", "solo", "collab", "collaborative", "trainee", "learner", "menu", "session", "content", "experience", "guest", "standard", "offline", "internet", "wi-fi", "wifi"] },

		


		{ section: "best-practices", link: "acclimatisation-environ", image: "headset", title: "Acclimatisation to Immersive Environments", description: "How to acclimitise your organisation to VR technology", loginReq: false, traineeGuard: false, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, keywords: ["VR"] },
		{ section: "best-practices", link: "stronger-responses", image: "https://facilitatestorage.blob.core.windows.net/$web/help-vr-training.png", title: "Stronger Emotional and Physical Responses", description: "How to best leverage stronger emotional responses", loginReq: false, traineeGuard: false, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, keywords: ["VR"] },
		{ section: "best-practices", link: "implement-vr", image: "headset", title: "Virtual Reality Implementation", description: "How to best introduce the concept of VR for your organisation", loginReq: false, traineeGuard: false, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, keywords: ["VR"] },

	];


	// Article link strings only
	public RECOMMENDED: string[] = [
		"assetlibrary",
		"presenting-in-vr",
		"assess-in-vr",
		"oculusquestheadset"
	];

	public QUICKACCESS: Section[] = [
		{ section: "", link: "troubleshooting", image: "troubleshoot", title: "Troubleshooting", description: "Have run into an issue? Check our documentation for troubleshooting common issues.", loginReq: true, traineeGuard: true, facilitatorGuard: true, masterOrgGuard: false, resellerGuard: false, page: false },
		{ section: "", link: "downloads", image: "downloads", title: "Downloads", description: "Get the Facilitate Desktop and VR apps", loginReq: false, traineeGuard: false, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, page: true },
		{ section: "", link: "documents", image: "help-pdf", title: "Documents", description: "Technical Documents to help your Admin/IT integrate further with Facilitate", loginReq: true, traineeGuard: true, facilitatorGuard: true, masterOrgGuard: false, resellerGuard: false, page: true },
		// { section: "", link: "contact-us", image: "contact", title: "Contact Us", description: "Contact us if you have any questions, requests, issues or general thoughts", loginReq: true, traineeGuard: false, facilitatorGuard: false, masterOrgGuard: false, resellerGuard: false, page: true } // specific message for trainees inside instead, allow access
	];

	// DO NOT DELETE FROM WHATSNEW, notification system is based on length of this array
	// To remove a post, set the body to "" and it will be hidden
	public WHATSNEW: NewsItem[] = [
		{ dateString: "September 6, 2022", title: "Brand New Micro-sim Editor", body: "Creating Micro-simulations have never been so easy. With our brand new interface, we have optimised the creation process, eliminating the complexities of VR simulations. Check it out now!", link: "help/present/microsim-editor", linkText: "Please read the help articles carefully to understand how you create impactful immersive Micro-simulations." },
		{ dateString: "February 1st, 2022", title: "Micro-sim Editor", body: "You can now create your own custom Micro-simulation. Simply upload an optimised 3D model (see our guides*) and create a sequence of actions, such as buttons, goaled movement or free move.", link: "help/present/microsim-editor", linkText: "Please read the help articles carefully to understand how you create impactful immersive Micro-simulations." },
		{ dateString: "January 5th, 2022", title: "Master and Sub Organisations", body: "Facilitate now lets you connect with other Organisations through our Master Organisation feature. Share content and Experiences with a number of Sub-Organisations all in one simple place. To enable this premium feature please contact the Facilitate team.", link: "", linkText: "" },
		{ dateString: "November 16th, 2021", title: "Flexible Facilitators", body: "Allow your Learners to take charge of Facilitate Sessions! Flexible Facilitator Sessions enables anyone within the Session to control the content, ask assessment questions, and lead Practice Scenarios.", link: "help/get-started/schedule-session", linkText: "Read more about how this works here." },
		{ dateString: "September 1st, 2021", title: "SCORM Integration", body: "You can now export Facilitate Sessions to your SCORM compatible LMS. Simply select the Export button from your Experience list, then import the SCORM file to your LMS. Collaborative Sessions are perpetually open until you choose to end the Session from your local LMS.", link: "", linkText: "" },
		{ dateString: "June 28th, 2021", title: "Updated learning spaces and menus", body: "Our number one priority is creating smooth and intuitive experiences for your users. As headsets are getting more powerful, we can create even more impressive learning environments. The Facilitate menus are now optimised to suit your Learners and create better learning experiences. This includes the option to join content when you enter a session late.", link: "", linkText: "" },
		{ dateString: "June 28th, 2021", title: "The Vive Focus 3", body: "Facilitate is now available to download from the Vive Business AppStore. Facilitate is supported on the latest Vive enterprise headset, the Vive Focus 3. We also support the Vive Focus Plus headset.", link: "help/downloads", linkText: "Available for download now!" },
		{ dateString: "June 28th, 2021", title: "Audio and Videos in 360 Scenarios", body: "You can now add audio and videos to your 360 Scenario experiences. Create immersive learning scenarios with your SME’s insights and knowledge.", link: "library/list/Interactive", linkText: "Checkout what you can create in the 360 Scenario creator through your modern browser." },
		{ dateString: "June 2nd, 2021", title: "Audio Content", body: "You can now upload .mp3 and .wav files to your Facilitate Library. Capture SME's insights, add them to your Experience, and deliver even more impactful learning to your Learners.", link: "library/list/Audio", linkText: "Check it out now in your Library" },
		{ dateString: "June 2nd, 2021", title: "Questions in 360 Scenarios", body: "Facilitate now lets you ask multiple choice questions in 360 scenarios. Assess your learner's knowledge, and prompt questions while fully immersed in a contextualised environment. Export the assessment data as a csv or to your local LMS.", link: "library/list/Interactive", linkText: "Try it now from your Library" },
		{ dateString: "May 3rd, 2021", title: "Standard Images", body: "This Facilitate update now allows you to upload regular png and jpg images. Also, the latest release of the 360 Scenario Creator now lets you to add standard image hotspots. Easily overlay images on top of your immersive videos and images to provide more information for your Learners.", link: "library/list/Image2D", linkText: "Check it out now in your Library" },
		{ dateString: "May 3rd, 2021", title: "Waypoints", body: "The 360 Scenario Creator now lets you create 360 journeys using our waypoint hotspot system. Easily create walk-through experiences and connect different scenes, ideal for inductions.", link: "", linkText: "" },
		{ dateString: "May 3rd, 2021", title: "Vive Focus Plus", body: "We are now compatible for the Vive Focus plus headset. If you would like to know more about how to get the Vive Facilitate app, please contact us.", link: "help/contact-us/queries", linkText: "Contact Form" },
		{ dateString: "March 2nd, 2021", title: "360 Scenario Content", body: "You can now create 360 Scenario Content in the asset library for your Learners to experience in their sessions. This includes multiple scenes of immersive videos and images and the ability to add hotspots to the surroundings.", link: "help/present/interactive-editor", linkText: "Learn how to use it here" },
		{ dateString: "November 23rd, 2020", title: "Experience Attachments", body: "You can now attach files to your Experiences for your Learners to download after their learning session. This also includes external web-links, allowing you to attach google drive files. Attachments are not Experience content, and they will not show up in the Experiences materials in a session. Attachments can be accessed on Desktop either on the Experience summary section or on the Experience details section. They can also be accessed on the review section in the web portal.", link: "", linkText: "" },
		{ dateString: "November 23rd, 2020", title: "New Avatar outfits", body: "We have added more avatar clothing options for your Facilitate avatars. This includes 4 new High Vis shirts and 4 new casual clothing options. Check out your profile and try on a new outfit!", link: "", linkText: "" },
		{ dateString: "November 23rd, 2020", title: "The Solo Retreat", body: " You can now learn in a brand new Solo space. This serene environment provides a calming space for your learner to experience their Solo sessions. Check it out by scheduling a Solo session and picking the new environment.", link: "", linkText: "" },
		{ dateString: "October 19th, 2020", title: "Groups", body: "Easily section your organisation into groups using Group Management. Create content and experiences specifically for your created groups using our new Visibility function. Export session data from specific groups now on the Review page.", link: "", linkText: "" },
		{ dateString: "July 13th, 2020", title: "Help Page", body: "We have updated the help page to allow you to easily find answers to your questions about Facilitate. Use the search bar to find articles that will help you use the Facilitate platform.", link: "", linkText: "" },
		{ dateString: "July 13th, 2020", title: "Session and Experience editing", body: "You can now edit your Experiences after you created them, allowing you to easily make changes. You can also edit sessions after you have scheduled them. This allows you to change your session date, or invite/uninvite Learners.", link: "", linkText: "" },
		{ dateString: "July 13th, 2020", title: "Sequenced Content", body: "Facilitate now allows you to create Sequenced Experiences. View the link below to find out more about how to create a Sequenced Experience.", link: "help/get-started/sequenced-lpc", linkText: "See it here" },
		{ dateString: "July 13th, 2020", title: "Immersive Images", body: "Facilitate now allows you to upload Immersive images.", link: "", linkText: "" },
		// {dateString: "July 13th, 2020", title: "Connected Questions", body: "To improve the Experience creation process, you can now connect questions to your assets. To find out more how this new feature works, click the article below.", link: "help/assess/assess-connected-questions", linkText: "See it here"},
		{ dateString: "July 13th, 2020", title: "Asset Library", body: "We have improved the functionality of the asset library, allowing you to preview your uploaded assets, and edit details. View more information below.", link: "help/get-started/assetlibrary", linkText: "See it here" }
	];
}