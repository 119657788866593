import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/services/accounts/authentication.service';
import { Userpilot } from 'userpilot';

@Injectable({
	providedIn: 'root'
})
export class AnonymousGuard implements CanActivate {

	path: ActivatedRouteSnapshot[];
	route: ActivatedRouteSnapshot;

	constructor(private router: Router) { }

	canActivate() {
        Userpilot.reload();
        return true;
	}

	canActivateChild(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	  ): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {
        var url = new URL(window.location.href).origin + state.url;
        Userpilot.reload({url: url});
        return true;
	}
}
