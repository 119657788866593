import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router'
import { appRoutes } from './app-routing.module';
import { NgModule } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { AppComponent } from './app.component';
import { NavbarComponent } from './elements/navbar/navbar.component';
import { HelpbarComponent } from './elements/helpbar/helpbar.component';
import { AngularFireModule, FIREBASE_OPTIONS } from '@angular/fire';
import { environment } from '../environments/environment';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireAnalyticsModule, CONFIG, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AuthenticationService } from 'src/app/services/accounts/authentication.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptorService } from 'src/app/services/token-interceptor.service';
import { SuperAdminGuard } from 'src/app/shared/guards/superadmin.guard';
import { AdminGuard } from 'src/app/shared/guards/admin.guard';
import { FacilitatorGuard } from 'src/app/shared/guards/facilitator.guard';
import { TraineeGuard } from 'src/app/shared/guards/trainee.guard';
import { ResellerGuard } from './shared/guards/reseller.guard';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe, CommonModule } from '@angular/common'
import { LoadingSpinnerComponent } from './elements/loading-spinner/loading-spinner.component';
import { LoadingService } from 'src/app/services/loading.service';
import { OverlayContainer, FullscreenOverlayContainer, OverlayModule } from '@angular/cdk/overlay';
import { LoadingSpinnerWrapperComponent } from './elements/loading-spinner-wrapper/loading-spinner-wrapper.component';
import { AlertComponent } from './elements/alert/alert.component';
import { AlertService } from 'src/app/services/alert.service';
import { ConfirmationService } from 'src/app/services/confirmation.service';
import { ConfirmationPopupComponent } from './elements/confirmation-popup/confirmation-popup.component';
import { HelpContentService } from './services/help-content.service';
import { AccountService } from './services/accounts/account.service';
import { SchedulerModule } from 'src/app/sections/scheduler/scheduler.module'; // not sure why I can't remove this one without breaking Experiences and Schedule with no error logs...
import { ErrorLoggingService } from './services/error-logging.service';
import { PageNotFoundComponent } from './sections/page-not-found/page-not-found.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { PasswordResetComponent } from './sections/password-reset/password-reset.component';
import { CookieService } from 'ngx-cookie-service';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HelpbarComponent,
    LoadingSpinnerComponent,
    LoadingSpinnerWrapperComponent,
    AlertComponent,
    ConfirmationPopupComponent,
    PageNotFoundComponent
      ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    RouterModule.forRoot(appRoutes),
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireAnalyticsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    OverlayModule,
    NgbTooltipModule,
    NgxDropzoneModule,
    // RecaptchaV3Module
  ],
  providers: [
    UserTrackingService,
    ScreenTrackingService,
    { provide: CONFIG, useValue:
      {
        send_page_view: false
      }
    },
    HttpClientModule,
    DatePipe,
    AuthenticationService,
    AccountService,
    SuperAdminGuard,
    AdminGuard,
    FacilitatorGuard,
    TraineeGuard,
    ResellerGuard,
    LoadingService,
    ConfirmationService,
    AlertService,
    CookieService,
    HelpContentService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    {
      provide: OverlayContainer,
      useClass: FullscreenOverlayContainer
    },
    ErrorLoggingService,
    {provide: FIREBASE_OPTIONS, useValue: environment.firebase},
    // { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptcha }
  ],
  entryComponents: [ LoadingSpinnerWrapperComponent ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
