import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/accounts/authentication.service';
import { Userpilot } from 'userpilot';

@Injectable({
	providedIn: 'root'
})
export class InternalGuard implements CanActivate {

	path: ActivatedRouteSnapshot[];
	route: ActivatedRouteSnapshot;

	constructor(public authService: AuthenticationService, private router: Router) { }

	canActivate() {
		const currentUser = this.authService.CurrentUserProfile;
		if(currentUser && currentUser.uid == "pOwupAoqZic4wBySFwjcVIWSRVY2") // Facilitate Master
		{
			Userpilot.reload();
			return true;
		}
		else
		{
			// not logged in so redirect to login page with the return url
			this.router.navigate(['/login']);//, { queryParams: { returnUrl: state.url } });
			return false;
		} 
	}
}
