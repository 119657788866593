import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { HelpbarComponent } from './elements/helpbar/helpbar.component';
import { AccountService } from './services/accounts/account.service';
import { LoadingService } from './services/loading.service';
import { Userpilot } from 'userpilot'
import { environment } from 'src/environments/environment';
import { HubspotTokenRequest, UserProfile } from './models/userprofile.model';
import * as hubspot from '@hubspot/api-client';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

	@ViewChild(HelpbarComponent) helpBar: HelpbarComponent;

	title = 'Facilitate Portal';
	helpNotificationCount: number = 0;

	private mobileWidth: number = 991; // match this to the @media css size
	private wasMobileWidth: boolean = false; // pairs with get isMobileWidth
	isMobile: boolean = false;
	tokenRequest: HubspotTokenRequest;
	showNavbar: boolean = true;
	private canHideLoading: boolean = true;

	@HostListener('window:resize', ['$event'])
	onResize() {
		if (this.isMobileWidth != this.wasMobileWidth) {
			this.wasMobileWidth = this.isMobileWidth;
			// cleanup all dropdowns when entering or leaving mobile width
			$('.dropdown-menu').hide();
		}

		if ($(window).width() <= 430) {
			this.isMobile = true;
		}
		else {
			this.isMobile = false;
			Userpilot.initialize('NX-e1fed41e');

		}

	}

	currentUser: UserProfile;

	constructor(private router: Router,
		private accService: AccountService,
		private loadingService: LoadingService) { }

	ngAfterContentInit(): void {
		((h, o, t, j, a, r) => {
			h.hj =
				h.hj ||
				// eslint-disable-next-line func-names
				function () {
					// eslint-disable-next-line prefer-rest-params
					(h.hj.q = h.hj.q || []).push(arguments);
				};
			h._hjSettings = { hjid: environment.hotjar, hjsv: 6 };
			// eslint-disable-next-line prefer-destructuring, no-param-reassign
			a = o.getElementsByTagName('head')[0];
			// eslint-disable-next-line no-param-reassign
			r = o.createElement('script');
			r.async = 1;
			r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
			a.appendChild(r);
		})(
			window as any,
			document,
			'https://static.hotjar.com/c/hotjar-',
			'.js?sv=',
		);
	}

	async ngOnInit() {

		this.router.events.subscribe(event => {
			if (event instanceof NavigationStart) {
				this.canHideLoading = false;
				this.loadingService.show();
				$(".modal-backdrop").hide(); // clear lingering modal background on all navigations
				this.accService.getCurrentOrg().then((org) => {
					if (this.canHideLoading) {
						this.loadingService.hide();
					}

					if (org) {
						if (event.url == "/inactive") {
							// keep out of inactive page when not needed
							if (!this.accService.blockedSubStatusList.includes(org.subscriptionStatus)) {
								this.router.navigate(['../home']);
							}
						}
						else {
							// block access based on subscriptionStatus
							if (event.url != "/"
								&& event.url != "/login"
								&& event.url.indexOf("/orgsignup") == -1
								&& event.url.indexOf("/signup") == -1
								&& event.url.indexOf("/suborglink") == -1
								&& event.url.indexOf("/inactive") == -1
								&& event.url.indexOf("/help") == -1
								&& event.url.indexOf("/reset") == -1) // still allow access to these without login or paid org
							{
								if (this.accService.blockedSubStatusList.includes(org.subscriptionStatus)) {
									this.router.navigate(['../inactive']);
								}
							}
				
						}
					}
				});
			}

			if (event instanceof NavigationEnd) {
				this.canHideLoading = true;
				if (this.accService.currentOrgLoaded
					|| event.urlAfterRedirects == "/"
					|| event.urlAfterRedirects == "/login"
					|| event.urlAfterRedirects.indexOf("/orgsignup") != -1
					|| event.urlAfterRedirects.indexOf("/signup") != -1
					|| event.urlAfterRedirects.indexOf("/suborglink") != -1
					|| event.urlAfterRedirects.indexOf("/inactive") != -1
					|| event.urlAfterRedirects.indexOf("/help") != -1
					|| event.url.indexOf("/reset") != -1
					|| event.urlAfterRedirects.indexOf("/Interactive/View") != -1
					|| event.urlAfterRedirects.indexOf("/MicroSim/View") != -1) // prevent blocking these with no prior login or with unpaid org
				{
					this.loadingService.hide();

				}
			}
		});


		if ($(window).width() <= 430) {
			this.isMobile = true;
		}
		else{
			Userpilot.initialize('NX-e1fed41e');

		}
		// solution for bootstrap dropdown getting lost under other elements
		// hold onto the drop down menu                                             
		var dropdownMenu;
		var target;

		// and when you show it, move it to the body                                     
		$(window).on('show.bs.dropdown', function (e) {
			target = e;
			// grab the menu        
			dropdownMenu = $(e.target).find('.dropdown-menu');

			// detach it and append it to the body
			$('body').append(dropdownMenu.detach());

			// grab the new offset position
			var eOffset = $(e.target).offset();

			// make sure to place it where it would normally go (this could be improved)
			dropdownMenu.css({
				'display': 'block',
				'top': eOffset.top + $(e.target).outerHeight(),
				'left': eOffset.left
			});
		});

		// and when you hide it, reattach the drop down, and hide it normally                                                   
		$(window).on('hide.bs.dropdown', function (e) {
			$(e.target).append(dropdownMenu.detach());
			dropdownMenu.hide();
			target = null;
		});

		// clean up rogue menus
		$(document).on("click", function () {
			if (target) {
				$(target.target).append(dropdownMenu.detach());
				dropdownMenu.hide();
				target = null;
			}
		})

		 Userpilot.initialize('NX-e1fed41e');

		this.currentUser = this.accService.currentUser;
		if (this.currentUser) {
			this.tokenRequest = {
				email: this.currentUser.email,
				firstName: this.currentUser.firstName,
				lastName: this.currentUser.lastName
			}

			this.accService.getHubspotToken(this.tokenRequest).then((token) => {
				var tokenString = token.token.toString();

				(window as any).hsConversationsSettings = {
					identificationEmail: this.currentUser.email,
					identificationToken: tokenString
				};

				(window as any).HubSpotConversations.widget.load();
			});
		}
	}

	get isMobileWidth() {
		return $(window).width() <= this.mobileWidth;
	}

	public toggleHelpBar(event: boolean) {
		this.helpBar.toggle(event);
	}

	public setHelpNotificationCount(event: number) {
		setTimeout(() => this.helpNotificationCount = event, 0);
	}

	toggleSideBar(event) {

		switch (event) {
			case 'open':
				(<HTMLInputElement>document.querySelector(".portal-content")).style.gridTemplateColumns = "15em auto";
				(<HTMLInputElement>document.querySelector("#mainContent")).style.width = "calc(100vw - 15em)";
				break;
			case 'close':
				(<HTMLInputElement>document.querySelector(".portal-content")).style.gridTemplateColumns = "5em auto";
				(<HTMLInputElement>document.querySelector("#mainContent")).style.width = "calc(100vw - 5em)";
				break;
			case 'mobileOpen':
				break;
			case 'mobileClose':
				break;
		}
	}
}
