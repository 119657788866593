import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InternalHomeButtonComponent } from './home-button.component';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
	declarations: [
		InternalHomeButtonComponent
	],
	imports: [
		CommonModule,
		RouterModule,
		HttpClientModule
	],
	exports:[
		InternalHomeButtonComponent
	]
})
export class InternalHomeButtonModule { }

