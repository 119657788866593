import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Group } from 'src/app/models/group.model';

@Component({
	selector: 'app-group-side-bar',
	templateUrl: './group-side-bar.component.html',
	styleUrls: ['./group-side-bar.component.css']
})
export class GroupSideBarComponent implements OnInit {

	@Input() groups: Group[] = [];
	@Input() currentGroupId: string = "";
	@Input() showCreateNew: boolean = false;
	@Input() showAllUsers: boolean = false;
	@Input() showReturn: boolean = false;
	@Input() showCurrent: boolean = true;
	@Input() showAddAll: boolean = false;
	@Input() addAllNum: number = 0;
	@Input() showInviteGroups: boolean = false;

	@Output() createEvent = new EventEmitter();
	@Output() gotoGroupEvent = new EventEmitter<string>();
	@Output() addAllEvent = new EventEmitter();
	@Output() returnEvent = new EventEmitter();

	constructor() { }

	ngOnInit() { }

	createNew() {
		this.createEvent.emit();
	}

	gotoGroup(groupId: string) {
		this.gotoGroupEvent.emit(groupId);
	}

	addAll() {
		this.addAllEvent.emit();
	}

	return() {
		this.returnEvent.emit();
	}

}
