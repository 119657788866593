import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Confirmation } from 'src/app/models/confirmation.model';
import { filter } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class ConfirmationService {
    constructor() {}

    private subject = new Subject<Confirmation>();

    // subscribe to confirmations
    getConfirmations(confirmationId?: string): Observable<any> {
        return this.subject.asObservable().pipe(filter((x: Confirmation) => x && x.confirmationId === confirmationId));
    }

    requestConfirmation(message: string, event: () => void, positive:boolean, acceptText:string, rejectText:string)
    {
        this.confirmation(new Confirmation({ message, action :event, positive:positive, acceptText:acceptText, rejectText:rejectText }));
    }

   /* use the messages string array to add confirmation messages in this format:
    string 1: the 'are you sure' message e.g: "Are you sure you want to delete this experience?"
    string 2: the experience/asset/item name e.g "Demo Experience"
    string 3: if the asset/exp in question has sessions/experiences that will be deleted as well e.g "All sessions (1) using this Experience will be cancelled."
    leave string 3 empty if no such message
    and set multiMessage to true */

    requestMultiConfirmation(messages: string[], multiMessage: boolean, event: () => void, positive:boolean, acceptText:string, rejectText:string, showUndoneMessage?: boolean)
    {
        this.confirmation(new Confirmation({ messages, multiMessage, action :event, positive:positive, acceptText:acceptText, rejectText:rejectText, showUndoneMessage:showUndoneMessage }));
    }
    
    requestConfirmationWithInput(message: string, event: () => void, positive:boolean, acceptText:string, rejectText:string, inputOn?: boolean, inputTextToMatch?: string){
        this.confirmation(new Confirmation({ message, action :event, positive:positive, acceptText:acceptText, rejectText:rejectText, inputOn:inputOn, inputTextToMatch:inputTextToMatch }));
    }

    confirmation(confirmation: Confirmation) {
        this.subject.next(confirmation);
    }

}