import { Injectable } from '@angular/core';
import { HubspotTokenRequest, UserProfile } from 'src/app/models/userprofile.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Invitation } from 'src/app/models/invitation.model';
import { environment } from '../../../environments/environment';
import { ErrorResponseHandler } from 'src/app/shared/error.handler';
import { Organisation, WhiteLabelContent, WhiteLabelContentWeb } from 'src/app/models/organisation.model';
import { OrganisationService } from './organisation.service';
import { GroupService } from '../group.service';
import { GroupUserItem } from 'src/app/models/records.model';
import { Router } from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class AccountService {

	private endpoint: string;
	currentUser: UserProfile = null;
	private currentOrg: Organisation = null;
	private currentSubOrgs: Organisation[] = [];
	private currentMasterOrgName: string = null;
	private currentResellerOrgName: string = null;
	currentOrgLoaded: boolean = false;
	currentSubOrgsLoaded: boolean = false; // applies for current MasterOrg if applicable instead
	currentResellerLoaded: boolean = false;

	// white labelling
	whiteLabelContent: WhiteLabelContentWeb = {navBarLogo: "", welcomeMessage: ""};
	whiteLabelContentLoaded: boolean = false;

	constructor(private http: HttpClient,
				private orgService: OrganisationService,
				private groupService: GroupService,
				private router: Router)
	{
		this.endpoint = environment.endpoint;
		try
		{
			this.currentUser = JSON.parse(localStorage.getItem('profile'));
			if(this.currentUser)
			{
				setTimeout(() => this.setOrgs());
			}
		}
		catch(e)
		{
			this.clearCurrentUserProfile();
			this.router.navigate(['../login']);
			location.reload();
		}
	}

	// used to block access to portal
	get blockedSubStatusList(): string[]
	{
		return ['unpaid','cancelled'];
	}

	public clearCurrentUserProfile()
	{
		this.currentUser = null;
		this.currentOrg = null;
		this.whiteLabelContent = {navBarLogo: "", welcomeMessage: ""};
		this.currentMasterOrgName = null;
		this.currentResellerOrgName = null;
		this.currentSubOrgs = [];
		this.groupService.clearCurrentGroups();
		this.currentOrgLoaded = true;
		this.currentSubOrgsLoaded = true;
		this.currentResellerLoaded = true;
		this.whiteLabelContentLoaded = true;
		this.currentResellerLoaded = true;
	}

	public setCurrentUserProfile(userProfile: UserProfile)
	{
		this.currentOrg = null;
		this.currentMasterOrgName = null;
		this.currentResellerOrgName = null;
		this.currentSubOrgs = [];
		this.groupService.clearCurrentGroups();
		this.currentUser = userProfile;
		this.setOrgs();
	}

	public setOrgs()
	{
		this.currentOrgLoaded = false;
		this.currentSubOrgsLoaded = false;
		this.currentResellerLoaded = false;
		if(this.currentUser)
		{
			this.orgService.getOrg(this.currentUser.organisationId)
			.then((org) => {
				if(org)
				{
					this.currentOrg = org;
					this.setWhiteLabellingCache();
					this.currentOrgLoaded = true;

					if(this.currentOrg.resellerOrgId != 0&&this.currentOrg.resellerOrgId != undefined)
					{
						this.orgService.getOrgName(this.currentOrg.resellerOrgId).then((resellerOrgName) => {
							this.currentResellerOrgName = resellerOrgName;
							this.currentResellerLoaded = true;
						});
					}
					else
					{
						this.currentResellerLoaded = true;
					}

					if(!this.blockedSubStatusList.includes(org.subscriptionStatus))
					{
						this.groupService.setCurrentGroups(this.currentUser.organisationId);

						if(this.currentOrg.isMasterOrg)
						{
							this.orgService.getSubOrgs(this.currentOrg.organisationId).then((subOrgs) => {
								this.currentSubOrgs = subOrgs;
								this.currentSubOrgsLoaded = true;
							});
						}
						else
						{
							if(this.currentOrg.masterOrgId != 0&&this.currentOrg.masterOrgId !=undefined)
							{
								this.orgService.getOrgName(this.currentOrg.masterOrgId).then((masterOrgName) => {
									this.currentMasterOrgName = masterOrgName;
									this.currentSubOrgsLoaded = true;
								});
							}
							else
							{
								this.currentSubOrgsLoaded = true;
							}
						}
					}
				}
			})
			.catch((err) =>
			{
				// org is missing?! hard fail, logout and go away
				this.clearCurrentUserProfile();
				this.currentUser = null;
				localStorage.removeItem('currentUserProfile');
				localStorage.removeItem('profile');
				localStorage.removeItem('token');
				this.router.navigate(['../login']);
			})
		}
	}

	public async getCurrentOrg(): Promise<Organisation>
	{
		if(this.currentUser)
		{
			while(!this.currentOrgLoaded || !this.whiteLabelContentLoaded) // non-page blocking waiting loop
			{
				await new Promise(resolve => setTimeout(resolve, 250));
			}
		}
		
		return this.currentOrg;
	}

	public async getCurrentSubOrgs(): Promise<Organisation[]>
	{
		if(this.currentUser)
		{
			while(!this.currentSubOrgsLoaded) // non-page blocking waiting loop
			{
				await new Promise(resolve => setTimeout(resolve, 250));
			}
		}

		return this.currentSubOrgs;
	}

	public async getCurrentMasterOrgName(): Promise<string>
	{
		if(this.currentUser)
		{
			while(!this.currentSubOrgsLoaded) // non-page blocking waiting loop
			{
				await new Promise(resolve => setTimeout(resolve, 250));
			}
		}

		return this.currentMasterOrgName;
	}

	public async getCurrentResellerOrgName(): Promise<string>
	{
		if(this.currentUser)
		{
			while(!this.currentResellerLoaded) // non-page blocking waiting loop
			{
				await new Promise(resolve => setTimeout(resolve, 250));
			}
		}

		return this.currentResellerOrgName;
	}

	get permissionsList(): string[] {
		// 'All' is used for filtering purposes
		return ["All", "Super Admin", "Admin", "Facilitator", "Trainee"];
	}

	get permissionsDisplayNameList(): {} {
		// 'All' is used for filtering purposes
		return {"All":"All", "Super Admin":"Super Admin", "Admin":"Admin", "Facilitator":"Coordinator", "Trainee":"Learner"};
	}

	public isRoleHigher(current: string, effected: string, same: boolean = false) : boolean {
		let permissionsOrder = this.permissionsList;
		let result = false;
		let currentIndex = permissionsOrder.findIndex(x => x == current);
		let effectedIndex = permissionsOrder.findIndex(x => x == effected);

		if(currentIndex != -1 && effectedIndex != -1)
		{
			if(same)
			{
				if(currentIndex <= effectedIndex)
				{
					result = true;
				}
			}
			else
			{
				if(currentIndex < effectedIndex)
				{
					result = true;
				}
			}
		}
		
		return result;
	}

	public isAdmin(currentRole: string): boolean {
		return this.isRoleHigher(currentRole,"Admin",true);
	}

	public isSuperAdmin(currentRole: string): boolean {
		return this.isRoleHigher(currentRole,"Super Admin",true);
	}

	public getOrganisationInvites(orgId: number) : Promise<Invitation[]> {
		return this.http.get<Invitation[]>(this.endpoint + '/api/Invitation/ByOrganisation/' + orgId,
			{ headers:{'Content-Type': 'application/json; charset=utf-8'}})
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public getUserProfiles(orgId: number) : Promise<UserProfile[]> {
		return this.http.get<UserProfile[]>(this.endpoint + '/api/UserProfile/ByOrganisation/' + orgId,
			{ headers: {'Content-Type': 'application/json; charset=utf-8'} })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public getUserProfileById(uid: string) : Promise<UserProfile> {
		return this.http.get<UserProfile>(this.endpoint + '/api/UserProfile/' + uid,
		{ headers: {'Content-Type': 'application/json; charset=utf-8'} })
		.pipe(catchError(ErrorResponseHandler.handleError))
			.toPromise();
	}

	public putUserProfile(updatedUserProfile: UserProfile) : Observable<Object> {
		return this.http.put<UserProfile>(this.endpoint + '/api/UserProfile/' + updatedUserProfile.uid, updatedUserProfile,
			{ headers:{'Content-Type': 'application/json; charset=utf-8'}})
			.pipe(catchError(ErrorResponseHandler.handleError));
	}

	public deleteUser(userProfile: UserProfile) : Promise<any> {
		return this.http.delete<any>(this.endpoint + '/api/UserProfile/' + userProfile.uid,
		{ headers: {'Content-Type': 'application/json; charset=utf-8'} })
		.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public assignCreatorLicense(userProfile: UserProfile): Promise<any> {
		return this.http.put<any>(this.endpoint + '/api/Organisation/AssignCreatorLicense/' + userProfile.uid,
		{ headers: {'Content-Type': 'application/json; charset=utf-8'} })
		.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public deassignCreatorLicense(userProfile: UserProfile): Promise<any> {
		return this.http.put<any>(this.endpoint + '/api/Organisation/DeassignCreatorLicense/' + userProfile.uid,
		{ headers: {'Content-Type': 'application/json; charset=utf-8'} })
		.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	//hubspot token
	public getHubspotToken(tokenRequest: HubspotTokenRequest) : Promise<any> {
		return this.http.post<any>(this.endpoint + '/api/UserProfile/HubspotToken', tokenRequest,
		  { headers: {'Content-Type': 'application/json; charset=utf-8'}})
		  .pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	  }

	//   public getHubspotToken(tokenRequest: HubspotTokenRequest) : Promise<any> {
	// 	var path = this.endpoint + '/api/UserProfile/HubspotToken/';
	// 	return this.http.request<any>('POST', path, {
	// 		body: tokenRequest,
	// 		headers: { 'Content-Type': 'application/json; charset=utf-8' }
	// 	}).pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	// }

	public convertUserProfileToGroupUserItem(user: UserProfile) : GroupUserItem {
		return {
			id: user.uid,
			name: user.displayName,
			email: user.email,
			role: this.permissionsDisplayNameList[user.role]
		}
	}

	get sortOptions(): string[] {
		return ["permissions", "displayname", "email"];
	}

	get sortOptionsDisplayName(): {} {
		return {"permissions": "Permission Level", "displayname": "Username", "email": "Email"};
	}

	public sortUsers(users: UserProfile[], sortType: string) : UserProfile[] {
		if(users)
		{
			let permissionsOrder = this.permissionsList;
			switch(sortType)
			{
				case "permissions":
					users.sort(function(a,b) {
						return (permissionsOrder.findIndex(x => x == a.role) < permissionsOrder.findIndex(x => x == b.role)) ? -1 : (permissionsOrder.findIndex(x => x == a.role) > permissionsOrder.findIndex(x => x == b.role)) ? 1 : 0;
					});
					break;
				case "displayname":
					users.sort(function(a,b)
					{
						return (a.displayName.trim().toLowerCase() < b.displayName.trim().toLowerCase()) ? -1 : (a.displayName.trim().toLowerCase() > b.displayName.trim().toLowerCase()) ? 1 : 0;
					});
					break;
				case "email":
					users.sort(function(a,b)
					{
						return (a.email.trim().toLowerCase() < b.email.trim().toLowerCase()) ? -1 : (a.email.trim().toLowerCase() > b.email.trim().toLowerCase()) ? 1 : 0;
					});
					break;
			}
		}
		return users;
	}

	public sortInvites(users: Invitation[], sortType: string) : Invitation[] {
		if(users)
		{
			let permissionsOrder = ["Admin", "Facilitator", "Trainee"];
			switch(sortType)
			{
				case "permissions":
					users.sort(function(a,b) {
						return (permissionsOrder.findIndex(x => x == a.role) < permissionsOrder.findIndex(x => x == b.role)) ? -1 : (permissionsOrder.findIndex(x => x == a.role) > permissionsOrder.findIndex(x => x == b.role)) ? 1 : 0;
					});
					break;
				case "displayname":
				case "email":
					users.sort(function(a,b)
					{
						return (a.email.trim().toLowerCase() < b.email.trim().toLowerCase()) ? -1 : (a.email.trim().toLowerCase() > b.email.trim().toLowerCase()) ? 1 : 0;
					});
					break;
			}
		}
		return users;
	}

	public sortAll(users: (UserProfile | Invitation)[], sortType: string): (UserProfile | Invitation)[] {
		if(users)
		{
			let permissionsOrder = this.permissionsList;
			switch(sortType)
			{
				case "permissions":
					users.sort(function(a,b) {
						return (permissionsOrder.findIndex(x => x == a.role) < permissionsOrder.findIndex(x => x == b.role)) ? -1 : (permissionsOrder.findIndex(x => x == a.role) > permissionsOrder.findIndex(x => x == b.role)) ? 1 : 0;
					});
					break;
				case "displayname":
					/*users.sort(function(a,b)
					{
						if(typeof(a)==typeof(UserProfile)&&typeof(b)!=typeof(UserPilot))
						{

						}
						return (a.displayName.trim().toLowerCase() < b.displayName.trim().toLowerCase()) ? -1 : (a.displayName.trim().toLowerCase() > b.displayName.trim().toLowerCase()) ? 1 : 0;
					});
					break;*/
				case "email":
					users.sort(function(a,b)
					{
						return (a.email.trim().toLowerCase() < b.email.trim().toLowerCase()) ? -1 : (a.email.trim().toLowerCase() > b.email.trim().toLowerCase()) ? 1 : 0;
					});
					break;
			}
		}
		return users;
	}

	public filterUsers(users: UserProfile[], options: {}) : UserProfile[] {
		if(Object.keys(options).length > 0)
		{
			for (let [key, value] of Object.entries(options)) {
				switch(key)
				{
					case "permissions":
						if(value != "All")
						{
							users = users.filter(user =>
								(user.role == value)
							);
						}
						break;
					case "text":
						users = users.filter(user =>
							(user.displayName.trim().toLowerCase() + user.email.trim().toLowerCase()).includes(value.toString().trim().toLowerCase())
						);
						break;
				}
			}
		}
		return users;
	}

	public filterInvites(invites: Invitation[], options: {}) : Invitation[] {
		if(Object.keys(options).length > 0)
		{
			for (let [key, value] of Object.entries(options)) {
				switch(key)
				{
					case "permissions":
						if(value != "All")
						{
							invites = invites.filter(invite =>
								(invite.role == value)
							);
						}
						break;
					case "text":
						invites = invites.filter(invite => 
							invite.email.trim().toLowerCase().includes(value.toString().trim().toLowerCase())
						);
						break;
				}
			}
		}
		return invites;
	}

	private setWhiteLabellingCache() {
		this.whiteLabelContentLoaded = false;
		if(this.currentOrg)
		{
			this.orgService.getWhiteLabelDisplayableContent(this.currentOrg.organisationId).then((result) => {
				this.whiteLabelContent = result.webContent;
				// parse welcome message tags
				this.whiteLabelContent.welcomeMessage = this.parseWelcomeMessage(this.whiteLabelContent.welcomeMessage);
				this.whiteLabelContentLoaded = true;
			});
		}
	}

	public updateWhiteLabellingCache(enabledFlag: boolean, newContent: WhiteLabelContent) {
		this.currentOrg.isWhiteLabelActive = enabledFlag;
		this.currentOrg.whiteLabelContent = newContent;
		this.setWhiteLabellingCache();
	}

	public parseWelcomeMessage(input: string): string {
		if(this.currentUser && input)
		{
			return input.replace("[%d]", this.currentUser.displayName)
						.replace("[%f]", this.currentUser.firstName)
						.replace("[%l]", this.currentUser.lastName);
		}
		else // covers some odd cases on logout
		{
			return input;
		}
	}
}
