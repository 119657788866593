import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Organisation } from 'src/app/models/organisation.model';
import { MultimediaService } from 'src/app/services/multimedia.service';

@Component({
	selector: 'app-org-example-assets',
	templateUrl: './example-assets.component.html',
	styleUrls: ['./example-assets.component.css']
})
export class OrgExampleAssetsComponent implements OnInit {

	@Input() isAdmin: boolean = false;
	@Input() org: Organisation;
	@Input() modalMode: boolean = false;

	internalExampleAssets: string[] = [];

	@Input() editMode: boolean = false;

	exampleAssetTags: string[] = this.multimediaService.exampleAssetTags;

	@Output() changeExampleAssetsEvent: EventEmitter<string[]> = new EventEmitter<string[]>();
	@Output() cancelExampleAssetsEvent = new EventEmitter();
	
	constructor(private multimediaService: MultimediaService) { }

	ngOnInit() {
		if(this.modalMode && this.isAdmin)
		{
			this.editMode = true;
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		for(const propName in changes)
		{
			switch(propName)
			{
				case "org":
					this.copyFromOrg();
					break;
			}
		}
	}

	ngAfterViewInit() {
		this.updateCheckBoxes();
	}

	copyFromOrg() {
		if(this.org && this.org.exampleAssets)
		{
			this.internalExampleAssets = this.org.exampleAssets.slice(); // clone
			this.updateCheckBoxes();
		}
	}

	updateCheckBoxes() {
		this.exampleAssetTags.forEach((tag) => 
		{
			let element = (<HTMLInputElement>document.getElementById(tag));
			if(element != null)
			{
				if(this.contains(tag) || this.contains('all'))
				{
					element.checked = true;
				}
				else
				{
					element.checked = false;
				}
			}
		});
	}

	enableEditMode() {
		this.editMode = true;
	}

	cancel() {
		if(this.modalMode)
		{
			this.cancelExampleAssetsEvent.emit();
		}
		else
		{
			this.editMode = false;
		}

		this.copyFromOrg();
		this.updateCheckBoxes();
	}

	save() {
		if(!this.modalMode)
		{
			this.editMode = false;
		}
		this.changeExampleAssetsEvent.emit(this.internalExampleAssets);
	}

	contains(category: string) : boolean {
		return this.internalExampleAssets.includes(category);
	}

	setNone() {
		this.internalExampleAssets = ['none'];
		this.updateCheckBoxes();
	}

	setAll() {
		this.internalExampleAssets = ['all'];
		this.updateCheckBoxes();
	}

	setTag(tag: string, checked: boolean) {
		if(checked)
		{
			if(this.contains('none'))
			{
				this.internalExampleAssets = [tag];
			}
			else
			{
				if(!this.contains(tag))
				{
					this.internalExampleAssets.push(tag);
				}
			}
		}
		else
		{
			if(this.contains('all'))
			{
				this.internalExampleAssets = this.exampleAssetTags.filter(x => x != tag);
			}
			else
			{
				this.internalExampleAssets = this.internalExampleAssets.filter(x => x != tag);
			}
		}
		this.updateCheckBoxes();
	}
}
