// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  envName: 'test',
  firebase: {
    apiKey: "AIzaSyB7Z59lSV-LZZKLe-76hB_HS-ApDtjnOSE",
    authDomain: "faciltiate-test.firebaseapp.com",
    databaseURL: "https://faciltiate-test.firebaseio.com",
    projectId: "faciltiate-test",
    storageBucket: "faciltiate-test.appspot.com",
    messagingSenderId: "668376099899",
    appId: "1:668376099899:web:bfbf7b3f184893c595c01e"
  },
  endpoint: "https://facilitate-cloud-facilitate-test-cloud.azurewebsites.net",
  blobsas: "https://facilitatestorage.blob.core.windows.net/?sv=2021-06-08&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2025-05-22T16:42:29Z&st=2022-08-29T08:42:29Z&spr=https&sig=imGWL3RkBYl4tUv5AQEVEUx8018FBN0%2Bh8eqCxjVk5I%3D",
  blobsasuk:"https://facilitatestorageuk.blob.core.windows.net/?sv=2020-08-04&ss=b&srt=co&sp=wactf&se=2025-05-22T13:27:46Z&st=2022-05-12T05:27:46Z&spr=https&sig=56dKdI8y6%2FzJmJTEuSbvVidKidH7vyP459Itn6DvHI8%3D",
  blobsasus:"https://facilitatestorageus.blob.core.windows.net/?sv=2020-08-04&ss=bfqt&srt=o&sp=rwdlacupitfx&se=2025-07-31T17:03:21Z&st=2022-05-23T09:03:21Z&spr=https&sig=NGfJaUeqoLn74BCbTOQC%2BWIZXSO6fPC4dV0C9o6a%2FU8%3D",
  stripePK: "pk_test_51JpNEqJ0bqMB6YidScFXk6ibMc45n9UB9p2cf1WYgCMUt1ADKnE4Ue16y2BfR5ody2yVGRxj785Kt3apCOSopyZI00QX4W2YHc",
  hotjar: 3671003,
  recaptcha: "6LdQTuwpAAAAAHdVFllFswGh0bPLsZ-I3hYS-A4T"

  // hubspotAccessToken: "pat-na1-51a26b33-3511-44f2-b22c-7b1827d35059"

};
