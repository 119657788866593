import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Section, Article, NewsItem } from '../models/help.model';
import { HttpClient } from '@angular/common/http';
import { HelpContentService } from './help-content.service';

@Injectable({
	providedIn: 'root'
})

export class HelpService {
	
	SECTIONS: Section[] = this.helpContent.SECTIONS;
	ARTICLES: Article[] = this.helpContent.ARTICLES;
	RECOMMENDED: string[] = this.helpContent.RECOMMENDED;
	WHATSNEW: NewsItem[] = this.helpContent.WHATSNEW;
	QUICKACCESS: Section[] = this.helpContent.QUICKACCESS;


	private endpoint: string;

	constructor(private http: HttpClient, private helpContent: HelpContentService) {
		this.endpoint = environment.endpoint;
	}

	public getSectionByLink (section: string) : Section
	{
		return this.SECTIONS.find(x => x.link == section);
	}

	public getArticlesBySectionLink (section: string) : Article[]
	{
		return this.ARTICLES.filter(x => x.section == section);
	}

	public getSectionsBySectionLink (section: string) : Section[]
	{
		return this.SECTIONS.filter(x => x.section == section);
	}

	public searchArticles (value: string) : Article[]{
		value = value.toLowerCase();
		let terms = value.split(' ').filter(x => x.length != 0);
		return this.ARTICLES.filter(x => terms.some(el => x.title.toLowerCase().includes(el)) || terms.some(el => x.keywords.includes(el) || terms.some(el => x.description.includes(el))));
	}

	public setNewsReadCount(userId: string, viewsRead: number) : Promise<any> {
		return this.http.post<any>(this.endpoint + '/API/Help/', {"userUid":userId, "articleNumber":viewsRead},
		{headers:{'Content-Type': 'application/json; charset=utf-8'}}).toPromise();
	}

	public getNewsReadCount(userId: string) : Promise<any> {
		return this.http.get<any>(this.endpoint + '/API/Help/'+ userId,
		{headers:{'Content-Type': 'application/json; charset=utf-8'}}).toPromise();
	}
}