import { NgModule } from '@angular/core';
import { Routes } from '@angular/router';
import { TraineeGuard } from './shared/guards/trainee.guard';
import { FacilitatorGuard } from './shared/guards/facilitator.guard';
import { AdminGuard } from './shared/guards/admin.guard';
import { SuperAdminGuard } from './shared/guards/superadmin.guard';
import { InternalGuard } from './shared/guards/internal.guard';
import { ResellerGuard } from './shared/guards/reseller.guard';
import { AnonymousGuard } from './shared/guards/anonymous.guard';
import { PageNotFoundComponent } from './sections/page-not-found/page-not-found.component';

export const appRoutes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full'},

  { path: 'login', loadChildren: './sections/login/login.module#LoginModule', canActivate: [AnonymousGuard],canActivateChild:[AnonymousGuard]},

  { path: 'orgsignup', loadChildren: './sections/signup/signup.module#SignupModule', data: {orgMode: true}, canActivate: [AnonymousGuard],canActivateChild:[AnonymousGuard]},
  { path: 'signup', loadChildren: './sections/signup/signup.module#SignupModule', data: {orgMode: false}, canActivate: [AnonymousGuard],canActivateChild:[AnonymousGuard]},
  { path: 'suborglink', loadChildren: './sections/sub-org-link-confirm/sub-org-link-confirm.module#SubOrgLinkConfirmModule', canActivate: [AnonymousGuard],canActivateChild:[AnonymousGuard]},

  { path: 'home', loadChildren: './sections/home/home.module#HomeModule', canActivate: [FacilitatorGuard],canActivateChild:[FacilitatorGuard]}, //fac guard since trainee home page is now the session page 08/03/24

  { path: 'inactive', loadChildren: './sections/inactive/inactive.module#InactiveModule', canActivate: [TraineeGuard],canActivateChild:[TraineeGuard]},

  { path: 'orgmanager', loadChildren: './sections/org-manager/org-manager.module#OrgManagerModule', canActivate: [FacilitatorGuard],canActivateChild:[FacilitatorGuard]},
  { path: 'subscriptionmanager', loadChildren: './sections/subscription-manager/subscription-manager.module#SubscriptionManagerModule', canActivate: [SuperAdminGuard],canActivateChild:[SuperAdminGuard]},
  { path: 'suborgsmanager', loadChildren: './sections/sub-orgs-manager/sub-orgs-manager.module#SubOrgsManagerModule', canActivate: [AdminGuard],canActivateChild:[AdminGuard]},
  { path: 'whitelabelmanager', loadChildren: './sections/whitelabel-manager/whitelabel-manager.module#WhiteLabelManagerModule', canActivate: [AdminGuard],canActivateChild:[AdminGuard]},
  { path: 'groupmanager', loadChildren: './sections/group-manager/group-manager.module#GroupManagerModule', canActivate: [FacilitatorGuard],canActivateChild:[FacilitatorGuard]},
  { path: 'accountsmanager', loadChildren: './sections/account-manager/account-manager.module#AccountManagerModule', canActivate: [FacilitatorGuard],canActivateChild:[FacilitatorGuard]},
  { path: 'billingmanager', loadChildren: './sections/billing-manager/billing-manager.module#BillingManagerModule', canActivate: [AdminGuard, ResellerGuard],canActivateChild:[AdminGuard]},
  { path: 'profile', loadChildren: './sections/profile/profile.module#ProfileModule', canActivate: [TraineeGuard],canActivateChild:[TraineeGuard]},

  { path: 'library', loadChildren: './sections/library/library.module#LibraryModule', canActivate: [FacilitatorGuard],canActivateChild:[FacilitatorGuard]},
  { path: 'experiences', loadChildren: './sections/lesson-plan/lesson-plan.module#LessonPlanModule', canActivate: [FacilitatorGuard],canActivateChild:[FacilitatorGuard]},
  { path: 'sessions', loadChildren: './sections/scheduler/scheduler.module#SchedulerModule', canActivate: [TraineeGuard],canActivateChild:[TraineeGuard]},
  { path: 'review', loadChildren: './sections/review/review.module#ReviewModule', canActivate: [TraineeGuard],canActivateChild:[TraineeGuard]},
  { path: 'help', loadChildren: './sections/help/help.module#HelpModule'},
  { path: 'reset', loadChildren: './sections/password-reset/password-reset.module#PasswordResetModule'},

  { path: 'internal', loadChildren: 'src/app/sections/internal-portal/internal-portal.module#InternalPortalModule', canActivate: [InternalGuard]},
  {path: 'Interactive/View/:contentId', loadChildren: './sections/asset-permission-check/asset-permission-check.module#AssetPermissionCheckModule', canActivate: [AnonymousGuard],canActivateChild:[AnonymousGuard]},
  {path: 'MicroSim/View/:contentId', loadChildren: './sections/asset-permission-check/asset-permission-check.module#AssetPermissionCheckModule', canActivate: [AnonymousGuard],canActivateChild:[AnonymousGuard]},
  //{ path: '**', redirectTo: 'login', canActivate: [AnonymousGuard],canActivateChild:[AnonymousGuard]}, // this must be last, it's a catch all

  //404 page
  { path: '**', pathMatch: 'full', component: PageNotFoundComponent}

  
];
