import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrgExampleAssetsComponent } from './example-assets.component';

@NgModule({
	declarations: [
		OrgExampleAssetsComponent
	],
	imports: [
		CommonModule
	],
	exports:[
		OrgExampleAssetsComponent
	]
})
export class ExampleAssetsModalModule { }
