import { Component } from '@angular/core';
import { ConfirmationService } from 'src/app/services/confirmation.service';
import { Confirmation } from 'src/app/models/confirmation.model';

@Component({
    selector: 'confirmation-popup',
    templateUrl: './confirmation-popup.component.html',
    styleUrls: ['./confirmation-popup.component.css']
})
export class ConfirmationPopupComponent {
  
  confirmations: any[] = [];
  //@Output() openConfirmation = new EventEmitter();
  showErrors: boolean = false;
  showErrors2: boolean = false;
  value: string = "";
  dataToggle:string = "";
  constructor(private confirmationService: ConfirmationService) { }

  ngOnInit() {
      this.confirmationService.getConfirmations().subscribe((confirmation: Confirmation) => {
          if (!confirmation.message && !confirmation.messages) {
              // clear alerts when an empty alert is received
              this.confirmations = [];
              return;
          }
          // add alert to array
          this.toggle();
          this.confirmations.push(confirmation);
      });

  }

  toggle(){
    //document.getElementById('button').click();
    (<any>$('#confirmation')).modal('show');
  }

  rejectConfirmation() {
    if(this.confirmations.length != 0)
    {
      this.confirmations.pop();
      (<any>$('#confirmation')).modal('hide');
      //this.toggle();
    }
  }


  //add function to check inputOn and then go to acceptConfirmation

  acceptConfirmation() {
    if (this.confirmations.length != 0) {
      if (this.confirmations[0].inputOn) {

        if (this.value === this.confirmations[0].inputTextToMatch) {
          let confirming: Confirmation = this.confirmations.pop()
          confirming.action();
          this.showErrors = false;
          this.showErrors2 = false;
          (<any>$('#confirmation')).modal('hide');
 
        }
        else if(this.value === "")
        {
          this.showErrors2 = true;
          this.showErrors = false;
        }
        else {
          this.showErrors = true;
          this.showErrors2 = false;
        }
      }
      else {
        let confirming: Confirmation = this.confirmations.pop()
        confirming.action();
        (<any>$('#confirmation')).modal('hide');
        //this.toggle();
      }


    }
  }

}
