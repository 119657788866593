import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges, HostListener } from '@angular/core';
import { AuthenticationService } from 'src/app/services/accounts/authentication.service';
import { NavigationStart, Router } from '@angular/router';
// import { Observable } from 'rxjs';
import { UserProfile } from 'src/app/models/userprofile.model';
import { AlertService } from 'src/app/services/alert.service';
import { AccountService } from 'src/app/services/accounts/account.service';
import { Organisation } from 'src/app/models/organisation.model';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { get } from 'jquery';
import { ConfirmationService } from 'src/app/services/confirmation.service';

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.css'],
	providers: [NgbTooltipConfig]
})

export class NavbarComponent implements OnInit {

	navbarOpen = true;
	mobileNavbarOpen = false;

	currentUser: UserProfile;
	currentOrg: Organisation;
	initials: string = '';

	accountNotification: boolean = false;
	accountNotificationRed: boolean = false;
	@Input() helpNotificationCount: number = 0;
	@Output() toggleHelpBarEvent = new EventEmitter<boolean>();
	@Output() toggleSideBarEvent = new EventEmitter<string>();

	isMobile: boolean = false;
	private mobileWidth: number = 991; // match this to the @media css size

	@HostListener('window:resize', ['$event'])
	onResize() {
		if ($(window).width() <= 430) {
			this.isMobile = true;
			this.mobileNavbarOpen = false;
			// if (this.isMobile) {
			// 	if (this.navbarOpen) {
			// 		this.navbarOpen = false;
			// 		//(<HTMLInputElement>document.querySelector(".side-nav-mobile-full")).style.display = 'none';
			// 	}
			// }
		}
		else {
			this.isMobile = false;
		}
	}

	constructor(private authService: AuthenticationService,
		private accService: AccountService,
		private router: Router,
		private alertService: AlertService,
		private confirmationService: ConfirmationService,
		tooltipConfig: NgbTooltipConfig) {
		tooltipConfig.placement = 'right';
		tooltipConfig.container = 'body';
		tooltipConfig.openDelay = 250;

		if ($(window).width() <= 430) {
			this.isMobile = true;
			this.mobileNavbarOpen = false;
		}

		this.router.events.subscribe((event) => {

			if (event instanceof NavigationStart) {
                if ($(window).width() <= 430) {
						this.mobileNavbarOpen = false;
						//(<HTMLInputElement>document.querySelector(".side-nav-mobile-full")).style.display = 'none';
				}
            }

			this.currentUser = this.accService.currentUser;
			this.accService.getCurrentOrg().then((currentOrg) => {
				this.currentOrg = currentOrg;
			})
		});
	}

	ngOnInit() {
		//this.currentUser = this.accService.currentUser;

	}

	ngAfterViewInit() {
		// if (this.isMobile) {
		// 	if (this.navbarOpen) {
		// 		this.navbarOpen = false;
		// 		(<HTMLInputElement>document.querySelector(".side-nav-mobile-full")).style.display = 'none';
		// 	}
		// }
		// else {
		// 	this.navbarOpen = true;
		// 	(<HTMLInputElement>document.querySelector(".side-nav-mobile-full")).style.display = 'none';
		// }
	}


	getInitials(string) {
		var names = string.split(' '),
			initials = names[0].substring(0, 1).toUpperCase();

		if (names.length > 1) {
			initials += names[names.length - 1].substring(0, 1).toUpperCase();
		}
		return initials;
	}

	toggleSideBar() {
		if (this.navbarOpen) {
			(<HTMLInputElement>document.querySelector(".side-nav")).style.width = "5em";
			this.toggleSideBarEvent.emit('close');
			this.navbarOpen = false;
		}
		else {
			(<HTMLInputElement>document.querySelector(".side-nav")).style.width = "15em";
			this.toggleSideBarEvent.emit('open');
			setTimeout(() => this.navbarOpen = true, 150);

		}

		//this.navbarOpen = !this.navbarOpen;
	}

	toggleSideBarMobile() {
		if (this.mobileNavbarOpen) {
			(<any>$("#sideNavMobile")).addClass('fadeOutLeft');
			//(<HTMLInputElement>document.querySelector(".side-nav-mobile-full")).style.display = 'none';
			this.toggleSideBarEvent.emit('mobileClose');
			this.mobileNavbarOpen = false;

		}
		else {
			(<HTMLInputElement>document.getElementById("sideNavMobile")).classList.remove("fadeOutLeft");
			(<HTMLInputElement>document.querySelector(".side-nav-mobile-full")).style.display = 'flex';
			// (<HTMLInputElement>document.querySelector(".side-nav-mobile")).style.width = "100%";
			this.toggleSideBarEvent.emit('mobileOpen');
			setTimeout(() => this.mobileNavbarOpen = true, 150);

		}
	}

	get isMobileWidth() {
		return $(window).width() <= this.mobileWidth;
	}

	toggleNavbar() {
		this.navbarOpen = !this.navbarOpen;
	}


	public logOut() {
		this.confirmationService.requestConfirmation("Are you sure you want to logout?", () => this.authService.logout(), false, "Yes, Logout", "Cancel");
	}

	public toggleHelpBar() {
		this.toggleHelpBarEvent.emit(true);
	}

	goToBillingPage() {
		this.router.navigateByUrl('/subscriptionmanager');
	}

	getTrialPeriodDays() {
		let startDate = new Date();
		let endDate = new Date(this.currentOrg.billingPeriodEnd);

		var trialDays = ((Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()) - Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate())) / (1000 * 60 * 60 * 24));

		//so that days never show negative values
		if (trialDays < 0) {
			trialDays = 0;
		}

		return trialDays;
	}

	getSubscriptionStatus() {
		var substatus = "";

		if (this.currentOrg != undefined) {
			substatus = this.currentOrg.subscriptionStatus;
		}
		else {
			substatus = "";
		}

		return substatus;
	}

	goTo(section: string) {
		switch (section) {
			case "Library":
				if (this.currentUser.role != 'Trainee') {
					this.router.navigateByUrl('/library');
					//(<HTMLInputElement>document.querySelector(".nav-list li")).style.backgroundColor = 'white';

				}
				else {
					this.alertService.warn("Only users of permission level 'Coordinator' or above can access the Library Page");
				}
				break;
			case "Experiences":
				if (this.currentUser.role != 'Trainee') {
					this.router.navigateByUrl('/experiences');;
				}
				else {
					this.alertService.warn("Only users of permission level 'Coordinator' or above can access the Experiences Page");
				}
				break;
			case "Schedule":
				this.router.navigateByUrl('/sessions');
				break;
			case "Review":
				this.router.navigateByUrl('/review');
				break;
		}
	}

	get navBarLogo(): string {
		return this.accService.whiteLabelContent.navBarLogo;
	}

	public checkRoute(navItem: string): boolean{
		if(this.router.url.startsWith(navItem)){
			return true;
		}
		else{
			return false;
		}
	}

}
