import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, iif, of, throwError } from 'rxjs';
import { AuthenticationService } from './accounts/authentication.service';
import { catchError, concatMap, delay, filter, retry, retryWhen, switchMap, take } from 'rxjs/operators';
import { Router } from '@angular/router';


@Injectable({
	providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {
	isRefreshing: boolean = false;
	private refreshTokenInProgress = false;
	// Refresh Token Subject tracks the current token, or is null if no token is currently
	// available (e.g. refresh pending).
	private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
		null
	);

	retryRequestOptions = {
		maximumRetries: 25,
		retryDelay: 1000,
	};
	constructor(private authService: AuthenticationService, private router: Router) { }

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {		// add auth token if available
		let token = this.authService.CurrentUserToken;
		if (token) {
			req = req.clone({
				setHeaders: {
					Authorization: `Bearer ${token}`
				}
			})
		}

		return next.handle(req).pipe(catchError((err) => {
			//handle auth error or rethrow
			switch (err.status) {
				case 401:
					this.authService.errorCode = err.status;
					if (err.error && err.error.message === 'TokenExpired') {
						return this.handle401Error(req, next); // more logic here so separating into a method
					}
					else {
						$(".modal-backdrop").hide(); // bug fix for forcing navigation with a modal open
						this.authService.logout();
						return of(err.message); // do not rethrow the error (double handling), return it here instead
					}
				case 403:
					$(".modal-backdrop").hide(); // bug fix for forcing navigation with a modal open
					this.authService.logout();
					return of(err.message); // do not rethrow the error (double handling), return it here instead
				case 402:
					$(".modal-backdrop").hide();
					this.router.navigate(['/inactive']).then(() => {
						//location.reload();
					});
					return of(err.message);
				case 429:
					this.authService.useRecaptcha = true;
					this.authService.errorCode = err.status;
					console.log(err.message);
					return of(err.message);

			}
			return throwError(err);
		}));
	}

	private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
		if (!this.isRefreshing) {

			this.isRefreshing = true;
			this.refreshTokenSubject.next(null);

			return this.authService.refreshToken().pipe(
				switchMap((newToken: any) => {
					this.isRefreshing = false;
					localStorage.setItem('token', newToken.result);
					this.refreshTokenSubject.next(newToken);
					return next.handle(this.addAuthenticationToken(request));
				}),
				catchError((err) => {
					//refresh token is rejected, either we've messed up or their refresh token has expired or they had no refresh token so they should be logged out
					this.isRefreshing = false;
					this.authService.logout();
					return throwError(err); // not sure if we should log an error
				})
			);


		}
		else {
			// If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
			// – which means the new token is ready and we can retry the request again
			return this.refreshTokenSubject.pipe(
				filter(token => token != null),
				take(1),
				switchMap(() => {
					console.log("here");
					return next.handle(this.addAuthenticationToken(request)); 
				})
			);
		}
	}

	addAuthenticationToken(request) {
		console.log("adding auth token")
		// Get access token from Local Storage
		const accessToken = localStorage.getItem('token');
		// If access token is null this means that user is not logged in
		// And we return the original request
		if (!accessToken) {
			return request;
		}

		// We clone the request, because the original request is immutable
		return request.clone({
			setHeaders: {
				Authorization: `Bearer ${accessToken}`
			}
		});
	}

}
