import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { ErrorResponseHandler } from '../../shared/error.handler';
import { ActiveUsersCount, ActiveUsersPerTimePeriod, Organisation, OrganisationEventsHistory, OrganisationSimple, OrganisationUsageInfo, OrgLinkInvite, PendingSignup, StorageLimit, StripeDetailsPackage, TrialReason, WhiteLabelContent } from 'src/app/models/organisation.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserProfile } from 'src/app/models/userprofile.model';
import { SubOrgItem, SubscribedOrgItem, VisibilityItem } from 'src/app/models/records.model';
import * as moment from 'moment-timezone';
import { NewSubscriptionRequest, StripeProduct, StripeProductForm } from 'src/app/models/stripe.model';
import { StripeService } from './stripe.service';

@Injectable({
	providedIn: 'root'
})
export class OrganisationService {

	private endpoint: string;

	constructor(private http: HttpClient,
		private fb: FormBuilder,
		private stripeService: StripeService) {
		this.endpoint = environment.endpoint;
	}

	get unleashedOrgIds(): number[] {
		return [1773581516, -1979519211, 648099049, 1142709780,
			-2100154559, -1979519211, -335440203, 648099049, 823378279, 1773581516];
	}

	get subStatusList(): string[] {
		return ['development', 'trial', 'active', 'unpaid', 'cancelled', 'reseller'];
	}

	public getAllOrgs(): Promise<Organisation[]> {
		return this.http.get<Organisation[]>(this.endpoint + '/api/Organisation',
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public getAllOrgsSimple(): Promise<OrganisationSimple[]> {
		return this.http.get<OrganisationSimple[]>(this.endpoint + '/api/Organisation/Simple',
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public getOrg(orgId: number): Promise<Organisation> {
		return this.http.get<Organisation>(this.endpoint + '/api/Organisation/' + orgId,
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' }, withCredentials: true })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public getOrgName(orgId: number): Promise<string> {
		return this.http.get<string>(this.endpoint + '/api/Organisation/Name/' + orgId,
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public getPendingSignups(): Promise<PendingSignup[]> {
		return this.http.get<PendingSignup[]>(this.endpoint + '/api/PendingSignup',
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public acceptPendingSignup(uid: string): Promise<any> {
		return this.http.put<any>(this.endpoint + '/api/PendingSignup/' + uid,
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public rejectPendingSignup(uid: string): Promise<any> {
		return this.http.delete<any>(this.endpoint + '/api/PendingSignup/' + uid,
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public createOrg(orgName: string, subStatus: string, masterOrgId: number, resellerOrgId: number, isMasterOrg: boolean, isResellerOrg: boolean, storage?: number, isManual?: boolean, isTrial?: boolean, isExtendedTrial?: boolean, billingStart?: Date, expiryDate?: Date, priceType?: string, currency?: string, numOfUsers?: number, creatorLicenses?: number, isAllowedWhiteLabel?: boolean, auPriceId: string = null, clPriceId: string = null): Promise<string> {
		return this.http.post<string>(this.endpoint + '/api/Organisation/New', { "organisationName": orgName, "subscriptionStatus": subStatus, "masterOrgId": masterOrgId, "resellerOrgId": resellerOrgId, "isMasterOrg": isMasterOrg, "isResellerOrg": isResellerOrg, "storageLimit": storage, "manualSubscription": isManual, "extendedTrial": isExtendedTrial, "billingPeriodStart": billingStart, "billingPeriodEnd": expiryDate, "priceType": priceType, "currency": currency, "numOfUsers": numOfUsers, "creatorLicenseLimit": creatorLicenses, "isAllowedWhiteLabel": isAllowedWhiteLabel, "priceId": auPriceId, "creatorLicensePriceId": clPriceId },
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public createOrgWithPayment(orgName: string, subStatus: string, resellerOrgId: number, isResellerRevenueShare: boolean, manualSubscription: boolean, customerId: string, productForm: StripeProductForm, paymentMethodId: string): Promise<string> {
		let paymentPackage = null;
		if (productForm != null) {
			paymentPackage = new StripeDetailsPackage(customerId, productForm, paymentMethodId)
		}
		return this.http.post<string>(this.endpoint + '/api/Organisation/NewWithPayment', { "organisation": { "organisationName": orgName, "subscriptionStatus": subStatus, "resellerOrgId": resellerOrgId, "isResellerRevenueShare": isResellerRevenueShare, "manualSubscription": manualSubscription }, "detailsPackage": paymentPackage },
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public deleteSignUpInvite(orgId: number): Promise<any> {
		return this.http.delete<any>(this.endpoint + '/api/Organisation/New/' + orgId,
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public createOrgLinkInvite(masterOrgId: number, inviteName: string): Promise<string> {
		return this.http.post<string>(this.endpoint + '/api/Organisation/NewLinkInvite/' + masterOrgId + "," + inviteName,
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public createOrgLinkInviteWithPayment(resellerOrgId: number, inviteName: string, revenueSharing: boolean, manualSubscription: boolean, customerId: string, productForm: StripeProductForm, paymentMethodId: string): Promise<string> {
		let paymentPackage = new StripeDetailsPackage(null, null, null);
		if (productForm != null) {
			paymentPackage = new StripeDetailsPackage(customerId, productForm, paymentMethodId)
		}
		return this.http.post<string>(this.endpoint + '/api/Organisation/NewLinkInviteWithPayment/' + resellerOrgId + "," + inviteName + "," + revenueSharing + "," + manualSubscription, paymentPackage,
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public useOrgLinkInvite(subOrgId: number, inviteId: string): Promise<any> {
		return this.http.post<any>(this.endpoint + '/api/Organisation/UseSubOrgInvite/' + subOrgId + "," + inviteId,
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public getOrgLinkInvite(inviteId: string): Promise<OrgLinkInvite> {
		return this.http.get<OrgLinkInvite>(this.endpoint + '/api/Organisation/SubOrgInvite/' + inviteId,
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public getOrgLinkInvites(invitingOrgId: number, type: string): Promise<OrgLinkInvite[]> {
		return this.http.get<OrgLinkInvite[]>(this.endpoint + '/api/Organisation/SubOrgInvitesByOrg/' + invitingOrgId + "," + type,
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public updateOrgName(orgId: number, name: string): Promise<any> {
		return this.http.put<any>(this.endpoint + '/API/Organisation/ChangeName/' + orgId + "," + name,
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public updateCurrency(orgId: number, currency: string): Promise<any> {
		return this.http.put<any>(this.endpoint + '/api/Organisation/ChangeCurrency/' + orgId + "," + currency,
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public updateExampleAssets(orgId: number, exampleAssets: string[]): Promise<any> {
		return this.http.put<any>(this.endpoint + '/API/Organisation/ExampleAssets/' + orgId, exampleAssets,
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public updateAllOrgDetails(org: Organisation): Promise<any> {
		return this.http.put<any>(this.endpoint + '/api/Organisation', org,
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public enableOrg(orgId: number): Promise<any> {
		return this.http.put<any>(this.endpoint + '/API/Organisation/Enable/' + orgId,
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public disableOrg(orgId: number): Promise<any> {
		return this.http.put<any>(this.endpoint + '/API/Organisation/Disable/' + orgId,
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	disableExtendedTrial(orgId: number): Promise<any> {
		return this.http.put<any>(this.endpoint + '/API/Organisation/DisableExtendedTrial/' + orgId,
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	enableExtendedTrial(orgId: number): Promise<any> {
		return this.http.put<any>(this.endpoint + '/API/Organisation/EnableExtendedTrial/' + orgId,
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public enableMasterOrg(orgId: number): Promise<any> {
		return this.http.put<any>(this.endpoint + '/API/Organisation/EnableMasterOrg/' + orgId,
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public disableMasterOrg(orgId: number): Promise<any> {
		return this.http.put<any>(this.endpoint + '/API/Organisation/DisableMasterOrg/' + orgId,
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public removeLinkedMasterOrg(subOrgId: number): Promise<any> {
		return this.http.put<any>(this.endpoint + '/API/Organisation/RemoveLinkedMasterOrg/' + subOrgId,
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	// public linkMasterOrg(subOrgId: number, masterOrgId: number) : Promise<any> {
	// 	return this.http.put<any>(this.endpoint + '/API/Organisation/LinkMasterOrg/' + subOrgId + "," + masterOrgId,
	// 	{ headers:{'Content-Type': 'application/json; charset=utf-8'}})
	// 	.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	// }

	public enableResellerOrg(orgId: number): Promise<any> {
		return this.http.put<any>(this.endpoint + '/API/Organisation/EnableResellerOrg/' + orgId,
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public disableResellerOrg(orgId: number): Promise<any> {
		return this.http.put<any>(this.endpoint + '/API/Organisation/DisableResellerOrg/' + orgId,
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public removeLinkedResellerOrg(subOrgId: number): Promise<any> {
		return this.http.put<any>(this.endpoint + '/API/Organisation/RemoveLinkedResellerOrg/' + subOrgId,
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public enableWhiteLabellingPermission(orgId: number): Promise<any> {
		return this.http.put<any>(this.endpoint + '/API/Organisation/WhiteLabel/EnablePermission/' + orgId,
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public disableWhiteLabellingPermission(orgId: number): Promise<any> {
		return this.http.put<any>(this.endpoint + '/API/Organisation/WhiteLabel/DisablePermission/' + orgId,
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public enableWhiteLabelling(orgId: number): Promise<any> {
		return this.http.put<any>(this.endpoint + '/API/Organisation/WhiteLabel/Enable/' + orgId,
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public disableWhiteLabelling(orgId: number): Promise<any> {
		return this.http.put<any>(this.endpoint + '/API/Organisation/WhiteLabel/Disable/' + orgId,
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public setWhiteLabellingContent(orgId: number, content: WhiteLabelContent): Promise<any> {
		return this.http.put<any>(this.endpoint + '/API/Organisation/WhiteLabel/SetContent/' + orgId, content,
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public getDefaultWhiteLabelContent(): Promise<WhiteLabelContent> {
		return this.http.get<WhiteLabelContent>(this.endpoint + '/API/Organisation/WhiteLabel/GetContent/default',
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public getWhiteLabelContent(orgId: number): Promise<WhiteLabelContent> {
		return this.http.get<WhiteLabelContent>(this.endpoint + '/API/Organisation/WhiteLabel/GetContent/' + orgId,
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public getWhiteLabelDisplayableContent(orgId: number): Promise<WhiteLabelContent> {
		return this.http.get<WhiteLabelContent>(this.endpoint + '/API/Organisation/WhiteLabel/GetDisplayableContent/' + orgId,
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public setAllSubOrgsWhiteLabelling(masterOrgId: number, value: boolean): Promise<any> {
		return this.http.put<any>(this.endpoint + '/API/Organisation/AllSubOrgsWhiteLabel/' + (value ? 'Enable' : 'Disable') + '/' + masterOrgId,
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public setMasterOrgWhiteLabelling(subOrgId: number, value: boolean): Promise<any> {
		return this.http.put<any>(this.endpoint + '/API/Organisation/MasterOrgWhiteLabel/' + (value ? 'Enable' : 'Disable') + '/' + subOrgId,
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public getOrgSignUpLink(orgId: number): Promise<string> {
		return this.http.get<string>(this.endpoint + '/API/Organisation/Link/' + orgId,
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public getOrgLinkInviteLink(inviteId: string): Promise<string> {
		return this.http.get<string>(this.endpoint + '/API/Organisation/SubLink/' + inviteId,
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public getSuperAdminFromOrgId(orgId: number): Promise<UserProfile> {
		return this.http.get<UserProfile>(this.endpoint + '/API/Organisation/SuperAdmin/' + orgId,
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public updateOrgSubStatus(orgId: number, status: string, periodStart: string = moment(new Date(0)).utc().format('DD/MM/YYYY HH:mm:ss'), periodEnd: string = moment(new Date(0)).utc().format('DD/MM/YYYY HH:mm:ss')): Promise<any> {
		return this.http.put<any>(this.endpoint + '/api/Organisation/ChangeSubscriptionStatus/' + orgId + "," + status, { "startDate": periodStart, "endDate": periodEnd },
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public getActiveUsers(orgId: number, periodStart: string = moment(new Date(0)).utc().format('DD/MM/YYYY HH:mm:ss'), periodEnd: string = moment(new Date(0)).utc().format('DD/MM/YYYY HH:mm:ss')): Promise<ActiveUsersCount> {
		return this.http.post<ActiveUsersCount>(this.endpoint + '/api/Organisation/ActiveUsers/' + orgId, { "startDate": periodStart, "endDate": periodEnd },
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public getSubOrgs(masterOrgId: number): Promise<Organisation[]> {
		return this.http.get<Organisation[]>(this.endpoint + '/api/Organisation/SubOrgs/' + masterOrgId,
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public getSubscribedOrgs(resellerOrgId: number): Promise<Organisation[]> {
		return this.http.get<Organisation[]>(this.endpoint + '/api/Organisation/ClientOrgs/' + resellerOrgId,
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public deleteSubOrgInvite(_id: string): Promise<any> {
		return this.http.delete<any>(this.endpoint + '/api/Organisation/SubOrgInvite/' + _id,
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public addTrialReason(trialReason: TrialReason): Promise<any> {
		return this.http.post<any>(this.endpoint + '/api/Organisation/TrialReason', trialReason,
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public getTrialReasons() {
		return this.http.get<TrialReason[]>(this.endpoint + '/api/Organisation/TrialReason/All',
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public enableOfflineAccess(orgId: number): Promise<any> {
		return this.http.put<any>(this.endpoint + '/API/Organisation/EnableOffline/' + orgId,
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public disableOfflineAccess(orgId: number): Promise<any> {
		return this.http.put<any>(this.endpoint + '/API/Organisation/DisableOffline/' + orgId,
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public getOrgInvitePaymentDetailPackage(resellerOrgId: number, inviteId: string, isNewOrg: boolean): Promise<StripeDetailsPackage> {
		return this.http.get<StripeDetailsPackage>(this.endpoint + '/api/Organisation/SubOrgInvite/PaymentDetails/' + resellerOrgId + "," + isNewOrg + "," + inviteId,
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public enableManualSubscription(orgId: number, plan: NewSubscriptionRequest): Promise<any> {
		return this.http.put<any>(this.endpoint + '/API/Organisation/EnableManualSub/' + orgId, plan,
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public disableManualSubscription(orgId: number): Promise<any> {
		return this.http.put<any>(this.endpoint + '/API/Organisation/DisableManualSub/' + orgId,
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public changeStorageLimit(org: Organisation, event: any): Promise<any> {
		return this.http.put<any>(this.endpoint + '/API/Organisation/ChangeStorageLimit/' + org.organisationId + "," + event,
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	changeCreatorLicenseLimit(org: Organisation, event: any) {
		return this.http.put<any>(this.endpoint + '/API/Organisation/ChangeCreatorLicenseLimit/' + org.organisationId + "," + event,
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	changeCreatorLicenseLimitWithId(org: number, event: any) {
		return this.http.put<any>(this.endpoint + '/api/Organisation/ChangeCreatorLicenseLimit/' + org + "," + event,
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public getCreatorLicenseUsage(organisationId: number): Promise<StorageLimit> {
		return this.http.get<StorageLimit>(this.endpoint + '/API/Organisation/CreatorLicense/' + organisationId,
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public getActiveUsersPerTimePeriod(orgId: number): Promise<ActiveUsersPerTimePeriod[]> {
		return this.http.get<ActiveUsersPerTimePeriod[]>(this.endpoint + '/api/Organisation/ActiveUsers/' + orgId,
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public getOrganisationUsageInfo(from: string, to: string): Promise<OrganisationUsageInfo[]> {
		return this.http.post<OrganisationUsageInfo[]>(this.endpoint + '/api/Organisation/OrganisationUsage', { "startDate": from, "endDate": to },
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public getOverallUsageMonthly(): Promise<OrganisationEventsHistory[]> {
		return this.http.get<OrganisationEventsHistory[]>(this.endpoint + '/api/OrganisationEvents/Monthly/',
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public getOverallUsageWeekly(): Promise<OrganisationEventsHistory[]> {
		return this.http.get<OrganisationEventsHistory[]>(this.endpoint + '/api/OrganisationEvents/Weekly/',
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public getOverallUsageYearly(): Promise<OrganisationEventsHistory[]> {
		return this.http.get<OrganisationEventsHistory[]>(this.endpoint + '/api/OrganisationEvents/Yearly/',
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public getOverallUsageQuarterly(): Promise<OrganisationEventsHistory[]> {
		return this.http.get<OrganisationEventsHistory[]>(this.endpoint + '/api/OrganisationEvents/Quarterly/',
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	//transfer content between orgs
	public transferOrgContent(fromOrgId: number, toOrgId: number, contentId: string, type: string, includeChildAssets: boolean, duplicateContent: boolean): Promise<any> {
		return this.http.put<any>(this.endpoint + '/api/LessonPlan/Transfer', { "fromOrganisationId": fromOrgId, "toOrganisationId": toOrgId, "contentId": contentId, "type": type, "includeChildAssets": includeChildAssets, "duplicateContent": duplicateContent },
			{ headers: { 'Content-Type': 'application/json; charset=utf-8' } })
			.pipe(catchError(ErrorResponseHandler.handleError)).toPromise();
	}

	public newOrgForm(): FormGroup {
		let form = this.fb.group({
			name: ['', [Validators.required]],
			subStatus: ['', [Validators.required]],
			masterOrgId: [0],
			resllerOrgId: [0],
			isMasterOrg: [false],
			isResellerOrg: [false],
			isManual: [false],
			isTrial: [false],
			isExtendedTrial: [false],
			storage: [0],
			expiryDate: [Date],
			eduNFP: [false],
			currency: ['aud'],
			numOfUsers: [0],
			creatorLicenses: [0],
			isAllowedWhiteLabel: [false]
		});
		return form;
	}

	public async convertOrgToSubOrgItem(organisation: Organisation): Promise<SubOrgItem> {
		let superAdmin = await this.getSuperAdminFromOrgId(organisation.organisationId);
		return {
			id: organisation.organisationId.toString(),
			name: organisation.organisationName,
			status: organisation.isInSignup ? "Pending" : "Connected",
			subscriptionStatus: organisation.subscriptionStatus.charAt(0).toUpperCase() + organisation.subscriptionStatus.slice(1),
			superAdminName: !organisation.isInSignup ? (superAdmin ? superAdmin.displayName : "[Super Admin Not Found]") : "",
			superAdminEmail: !organisation.isInSignup ? (superAdmin ? superAdmin.email : "") : "",
			isMasterOrgWhiteLabelActive: organisation.isMasterOrgWhiteLabelActive,
			existing: true,
			exampleAssets: organisation.exampleAssets
		}
	}

	public convertOrgLinkInviteToSubOrgItem(orgInvite: OrgLinkInvite): SubOrgItem {
		return {
			id: orgInvite.id,
			name: orgInvite.inviteName,
			status: "Pending",
			subscriptionStatus: "",
			superAdminName: "",
			superAdminEmail: "",
			isMasterOrgWhiteLabelActive: false,
			existing: false,
			exampleAssets: []
		}
	}

	public async convertOrgToSubscribedOrgItem(organisation: Organisation, discount: number, resellerCustId: string): Promise<SubscribedOrgItem> {
		let superAdmin = await this.getSuperAdminFromOrgId(organisation.organisationId);
		let subscriptionName: string = "";
		let repayType: string = "";
		let nextInvoiceResult: string = "";
		let CL = organisation.creatorLicenseLimit;
		let priceType = organisation.priceType;
		let subscriptionId = organisation.subscriptionId;
		let upcomingInvoiceAmount;

		if (!organisation.isResellerRevenueShare && organisation.isInSignup) {
			let paymentDetails = await this.getOrgInvitePaymentDetailPackage(organisation.resellerOrgId, organisation.organisationId.toString(), true);
			subscriptionName = paymentDetails.productForm.productName;
			let product = await this.stripeService.getProductByPriceId(paymentDetails.productForm.priceId);
			repayType = product.repayType;
		}
		else if (organisation.productId && organisation.priceId) {
			let product = await this.stripeService.getProduct(organisation.productId);
			let price = await this.stripeService.getPrice(organisation.priceId);
			subscriptionName = product.name;
			repayType = product.repayType;
			let activeUsers = await this.getActiveUsers(organisation.organisationId, moment(organisation.billingPeriodStart).utc().format('DD/MM/YYYY HH:mm:ss'), moment(organisation.billingPeriodEnd).utc().format('DD/MM/YYYY HH:mm:ss'));
			var additionalPrice;
			if (product.repayType == "annual" || product.repayType == "annualNew") {
				let tierIndex = this.stripeService.calculateTierIndex(organisation.numOfUsers, product, activeUsers);
				let products = await this.stripeService.getProducts();
				let currentPriceId = products.find(x => x.repayType == "additionalUser" && x.priceType == organisation.priceType && x.tier == (tierIndex + 1).toString()).prices.find(x => x.currency == price.currency).id;
				additionalPrice = await this.stripeService.getPrice(currentPriceId);
			}
			let calcNextInvoiceResults = this.stripeService.calculateNextInvoice(organisation.numOfUsers, organisation.autoRenew, product, activeUsers, price, discount, additionalPrice, CL, priceType);
			nextInvoiceResult = calcNextInvoiceResults.combinedNextInvoiceText;

			if (organisation.subscriptionStatus != 'active') {
				nextInvoiceResult = "";
			}

			if (subscriptionId != null && organisation.subscriptionStatus == 'active' && !organisation.isResellerRevenueShare && organisation.autoRenew == true) {

				await this.stripeService.getUpcomingInvoiceReseller(subscriptionId, resellerCustId).then((invoice) => {
					upcomingInvoiceAmount = this.stripeService.priceDisplay(invoice, price.currency);;
				}).catch((e) => {
					console.log(e);
					upcomingInvoiceAmount = "";
				});
			}
			else if (organisation.isResellerRevenueShare || organisation.subscriptionStatus != 'active') {
				upcomingInvoiceAmount = "";
			}
			else {
				upcomingInvoiceAmount = "No future invoices";
			}

		}





		return {
			id: organisation.organisationId.toString(),
			name: organisation.organisationName,
			subscriptionName: subscriptionName,
			subscriptionStatus: !organisation.isInSignup ? organisation.subscriptionStatus.charAt(0).toUpperCase() + organisation.subscriptionStatus.slice(1) : "Pending",
			repayType: repayType,
			superAdminName: !organisation.isInSignup ? (superAdmin ? superAdmin.displayName : "[Super Admin Not Found]") : "",
			superAdminEmail: !organisation.isInSignup ? (superAdmin ? superAdmin.email : "") : "",
			invoiceDate: (!organisation.isInSignup && organisation.subscriptionStatus != "trial" && organisation.subscriptionStatus != "development") ? organisation.billingPeriodEnd : null,
			nextInvoice: upcomingInvoiceAmount,
			isRevenueShare: organisation.isResellerRevenueShare,
			existing: true
		}
	}

	public async convertOrgLinkInviteToSubscribedOrgItem(orgInvite: OrgLinkInvite): Promise<SubscribedOrgItem> {
		let subscriptionName: string = "";
		let repayType: string = "";

		if (orgInvite.type == "ResellerDiscount") {
			let paymentDetails = await this.getOrgInvitePaymentDetailPackage(orgInvite.invitingOrgId, orgInvite.id, false);
			subscriptionName = paymentDetails.productForm.productName;
			let product = await this.stripeService.getProductByPriceId(paymentDetails.productForm.priceId);
			repayType = product.repayType;
		}

		return {
			id: orgInvite.id,
			name: orgInvite.inviteName,
			subscriptionName: subscriptionName,
			subscriptionStatus: "Pending",
			repayType: repayType,
			superAdminName: "",
			superAdminEmail: "",
			invoiceDate: null,
			nextInvoice: "",
			isRevenueShare: orgInvite.type == "ResellerRevenueShare",
			existing: false
		}
	}

	public convertOrgToVisibilityItem(organisation: Organisation): VisibilityItem {
		return {
			id: organisation.organisationId.toString(),
			name: organisation.organisationName,
			selected: false
		}
	}

	public filterOptions(orgs: Organisation[] = []): string[] {
		let options = ["", "0", "-1"];
		if (orgs && orgs.length > 0) {
			orgs.forEach(org => {
				options.push(org.organisationId.toString());
			});
		}
		return options;
	}

	public filterOptionsDisplayName(orgs: Organisation[] = []): {} {
		let names = { "": "All", "0": "Any Sub Org", "-1": "Not Shared" }

		if (orgs && orgs.length > 0) {
			orgs.forEach(org => {
				names[org.organisationId] = org.organisationName;
			});
		}
		return names;
	}

	public sortedSubOrgIdsByName(subOrgIds: number[], subOrgs: Organisation[]) {
		return subOrgIds.map(id => {
			let subOrg = subOrgs.find(x => x.organisationId == id);
			if (subOrg) {
				return subOrg.organisationName;
			}
			else {
				return "Unknown Sub Org";
			}
		}).sort(function (a, b) {
			return (a < b) ? -1 : (a > b) ? 1 : 0;
		});
	}

	// date tricks for use with billing periods
	public startOfMonth(date: Date, period: string): Date {
		switch (period) {
			case "annual":
			case "annualNew":
				while (moment(date) >= moment()) {
					date = moment(date).subtract(1, 'months').toDate();
				}
				break;
		}
		return date;
	}

	public endOfMonth(date: Date, period: string): Date { // adds 1 month, use with start of billing period date
		switch (period) {
			case "annual":
			case "annualNew":
				while (moment(date).subtract(1, 'months') >= moment()) {
					date = moment(date).subtract(1, 'months').toDate();
				}
				break;
		}
		return moment(date).subtract(1, 'days').toDate();
	}

	public getSubscriptionWord(org: Organisation): string {

		var subWord;
		if (org.subscriptionStatus == 'trial') {
			subWord = "f";
		}
		else {
			switch (org.numOfUsers) {
				case 50:
					subWord = "a";
					break;
				case 100:
					subWord = "b";
					break;
				case 200:
					subWord = "c";
					break;
				case 250:
					subWord = "d";
					break;
				default:
					subWord = "e";
					break;
			}
		}

		return subWord;
	}

	public filterOrgs(orgs: Organisation[], options: {}) {
		if (Object.keys(options).length > 0) {
			for (let [key, value] of Object.entries(options)) {
				switch (key) {
					case 'starterSubType':
						if (value != "" && value != null) {
							orgs = orgs.filter(x => x.numOfUsers == 50);
						}
						break;
					case 'midSubType':
						if (value != "" && value != null) {
							orgs = orgs.filter(x => x.numOfUsers == 100);
						}
						break;
					case 'growthSubType':
						if (value != "" && value != null) {
							orgs = orgs.filter(x => x.numOfUsers == 200);
						}
						break;
					case 'customSubType':
						if (value != "" && value != null) {
							orgs = orgs.filter(x => x.numOfUsers != 50 && x.numOfUsers != 100 && x.numOfUsers != 200 && x.numOfUsers != 250);
						}
						break;
					case 'largeSubType':
						if (value != "" && value != null) {
							orgs = orgs.filter(x => x.numOfUsers == 250);
						}
						break;
					case 'pending':
						if (value != "" && value != null) {
							orgs = orgs.filter(x => x.isInSignup == true);
						}
						break;
					case 'trial':
						if (value != "" && value != null) {
							orgs = orgs.filter(x => x.subscriptionStatus == 'trial');
						}
						break;
					case 'manual':
						if (value != "" && value != null) {
							orgs = orgs.filter(x => x.manualSubscription == true);
						}
						break;
					case 'stripe':
						if (value != "" && value != null) {
							orgs = orgs.filter(x => x.customerId != null && x.customerId != undefined && !x.manualSubscription);
						}
						break;
					case 'development':
						if (value != "" && value != null) {
							orgs = orgs.filter(x => x.subscriptionStatus == 'development');
						}
						break;
					case 'cancelled':
						if (value != "" && value != null) {
							orgs = orgs.filter(x => x.subscriptionStatus == 'cancelled');
						}
						break;
					case 'reseller':
						if (value != "" && value != null) {
							orgs = orgs.filter(x => x.subscriptionStatus == 'reseller');
						}
						break;
					case 'unpaid':
						if (value != "" && value != null) {
							orgs = orgs.filter(x => x.subscriptionStatus == 'unpaid');
						}
						break;
					case 'billingStartTodayCheck':
						if (value != "" && value != null) {
							let dateNow: Date = new Date();
							orgs = orgs.filter(org => ((new Date(org.billingPeriodStart).getDate() == dateNow.getDate() && new Date(org.billingPeriodStart).getMonth() == dateNow.getMonth() && new Date(org.billingPeriodStart).getFullYear() == dateNow.getFullYear())));
						}
						break;
					case 'billingStartWeekCheck':
						if (value != "" && value != null) {
							let dateNow: Date = new Date();
							const todayDate = dateNow.getDate();
							const todayDay = dateNow.getDay();

							// get first date of week
							const firstDayOfWeek = new Date(dateNow.setDate(todayDate - todayDay));

							// get last date of week
							const lastDayOfWeek = new Date(firstDayOfWeek);
							lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);

							// if date is equal or within the first and last dates of the week

							orgs = orgs.filter(org => ((new Date(org.billingPeriodStart).getTime() <= lastDayOfWeek.getTime() && new Date(org.billingPeriodStart).getTime() >= firstDayOfWeek.getTime())));
						}
						break;
					case 'billingStartMonthCheck':
						if (value != "" && value != null) {
							let dateNow: Date = new Date();
							orgs = orgs.filter(org => ((new Date(org.billingPeriodStart).getMonth() === dateNow.getMonth() && new Date(org.billingPeriodStart).getFullYear() == dateNow.getFullYear())));
						}
						break;
					case 'billingEndTodayCheck':
						if (value != "" && value != null) {
							let dateNow: Date = new Date();
							orgs = orgs.filter(org => ((new Date(org.billingPeriodEnd).getDate() == dateNow.getDate() && new Date(org.billingPeriodEnd).getMonth() == dateNow.getMonth() && new Date(org.billingPeriodEnd).getFullYear() == dateNow.getFullYear())));
						}
						break;
					case 'billingEndWeekCheck':
						if (value != "" && value != null) {
							let dateNow: Date = new Date();
							const todayDate = dateNow.getDate();
							const todayDay = dateNow.getDay();

							// get first date of week
							const firstDayOfWeek = new Date(dateNow.setDate(todayDate - todayDay));

							// get last date of week
							const lastDayOfWeek = new Date(firstDayOfWeek);
							lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);

							// if date is equal or within the first and last dates of the week

							orgs = orgs.filter(org => ((new Date(org.billingPeriodEnd).getTime() <= lastDayOfWeek.getTime() && new Date(org.billingPeriodEnd).getTime() >= firstDayOfWeek.getTime())));
						}
						break;
					case 'billingEndMonthCheck':
						if (value != "" && value != null) {
							let dateNow: Date = new Date();
							orgs = orgs.filter(org => ((new Date(org.billingPeriodEnd).getMonth() === dateNow.getMonth() && new Date(org.billingPeriodEnd).getFullYear() == dateNow.getFullYear())));
						}
						break;
				}
			}
		}
		return orgs
	}

	public sortOrgs(orgs: Organisation[], sortType: string): Organisation[] {
		if (orgs) {
			switch (sortType) {
				case "title":

					orgs.sort(function (a, b) {
						return (a.organisationName.trim().toLowerCase() < b.organisationName.trim().toLowerCase()) ? -1 : (a.organisationName.trim().toLowerCase() > b.organisationName.trim().toLowerCase()) ? 1 : 0;
					});

					break;
				case "titleReverse":

					orgs.sort(function (a, b) {
						return (a.organisationName.trim().toLowerCase() > b.organisationName.trim().toLowerCase()) ? -1 : (a.organisationName.trim().toLowerCase() < b.organisationName.trim().toLowerCase()) ? 1 : 0;
					});

					break;
				case "subscription":

					orgs.sort((a, b) => {
						return (this.getSubscriptionWord(a).trim().toLowerCase() < this.getSubscriptionWord(b).trim().toLowerCase()) ? -1 : (this.getSubscriptionWord(a).trim().toLowerCase() > this.getSubscriptionWord(b).trim().toLowerCase()) ? 1 : 0;
					});

					break;
				case "subscriptionReverse":

					orgs.sort((a, b) => {
						return (this.getSubscriptionWord(a).trim().toLowerCase() > this.getSubscriptionWord(b).trim().toLowerCase()) ? -1 : (this.getSubscriptionWord(a).trim().toLowerCase() < this.getSubscriptionWord(b).trim().toLowerCase()) ? 1 : 0;
					});

					break;
				case "startDateNew":
					orgs.sort(function (a, b) {

						let dateA, dateB;

						dateA = new Date(a.billingPeriodStart).getTime();
						dateB = new Date(b.billingPeriodStart).getTime();

						return dateB - dateA;
					});
					break;
				case "startDateOld":
					orgs.sort(function (a, b) {

						let dateA, dateB;

						dateA = new Date(a.billingPeriodStart).getTime();
						dateB = new Date(b.billingPeriodStart).getTime();

						return dateA - dateB;
					});
					break;
				case "endDateNew":
					orgs.sort(function (a, b) {

						let dateA, dateB;

						dateA = new Date(a.billingPeriodEnd).getTime();
						dateB = new Date(b.billingPeriodEnd).getTime();

						return dateB - dateA;
					});
					break;
				case "endDateOld":
					orgs.sort(function (a, b) {

						let dateA, dateB;

						dateA = new Date(a.billingPeriodEnd).getTime();
						dateB = new Date(b.billingPeriodEnd).getTime();

						return dateA - dateB;
					});
					break;
				// case "subTypeStarter":
				// 	orgs = orgs.filter(x => x.numOfUsers == 50);
				// 	break;
				// case "subTypeMid":
				// 	orgs = orgs.filter(x => x.numOfUsers == 100);
				// 	break;
				// case "subTypeGrowth":
				// 	orgs = orgs.filter(x => x.numOfUsers == 200);
				// 	break;
				// case "subTypeLarge":
				// 	orgs = orgs.filter(x => x.numOfUsers == 250);
				// 	break;
				// case "subTypeCustom":
				// 	orgs = orgs.filter(x => x.numOfUsers != 50 && x.numOfUsers != 100 && x.numOfUsers != 200 && x.numOfUsers != 250);
				// 	break;

			}
		}

		return orgs;
	}

}
