import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-internal-home-button',
	templateUrl: './home-button.component.html',
	styleUrls: ['../../../../elements/navbar/navbar.component.css', 
				'./home-button.component.css']
})

	export class InternalHomeButtonComponent implements OnInit {

	constructor(private router: Router) { }

	ngOnInit() { }

	public goHomeInternal() {
		this.router.navigateByUrl('internal')
	}
}
