import { HttpErrorResponse } from "@angular/common/http";
import { throwError } from 'rxjs';

export class ErrorResponseHandler {

  public static handleError(errorResponse: HttpErrorResponse) {
    if (errorResponse.error instanceof ErrorEvent){
      console.error('Client Side Error: ', errorResponse.error);
    } else{
      console.error('Server Side Error: ', errorResponse);
    }
    return throwError('There is a problem with the service');
  }
}

