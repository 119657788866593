import { Component, OnInit, Input } from '@angular/core';
import { UserProfile } from 'src/app/models/userprofile.model';

@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.css']
})
export class UserAvatarComponent implements OnInit {

  @Input() user: UserProfile;

  constructor() { }

  ngOnInit() {
  }

  public getInitials(): string {
    let initials: string = "";
    if(this.user !== null && this.user !== undefined){
      if(this.user.firstName !== null){
        initials = initials + this.user.firstName[0];
      }

      if(this.user.lastName !== null){
        initials = initials + this.user.lastName[0];
      }
    }

    return initials;
  }

}
