import { FormGroup, ValidationErrors } from '@angular/forms';
// import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

// custom validator to check that two fields match
// export function MustMatch(controlName: string, matchingControlName: string) : ValidationErrors{
//     return (formGroup: FormGroup) => {
//         const control = formGroup.controls[controlName];
//         const matchingControl = formGroup.controls[matchingControlName];

//         if (matchingControl.errors && !matchingControl.errors.mustMatch) {
//             // return if another validator has already found an error on the matchingControl
//             return;
//         }

//         // set error on matchingControl if validation fails
//         if (control.value !== matchingControl.value) {
//             matchingControl.setErrors({ mustMatch: true });
//         } else {
//             matchingControl.setErrors(null);
//         }
//     }
// }

// export function matchOtherValidator (otherControlName: string) {

//   let thisControl: FormControl;
//   let otherControl: FormControl;

//   return function matchOtherValidate (control: FormControl) {

//     if (!control.parent) {
//       return null;
//     }

//     // Initializing the validator.
//     if (!thisControl) {
//       thisControl = control;
//       otherControl = control.parent.get(otherControlName) as FormControl;
//       if (!otherControl) {
//         throw new Error('matchOtherValidator(): other control is not found in parent group');
//       }
//       otherControl.valueChanges.subscribe(() => {
//         thisControl.updateValueAndValidity();
//       });
//     }

//     if (!otherControl) {
//       return null;
//     }

//     if (otherControl.value !== thisControl.value) {
//       return {
//         matchOther: true
//       };
//     }

//     return null;

//   }
// }

// export function ngbDateCheckValid (dateValue: NgbDate) : Boolean {
//   if(new Date(dateValue.year, dateValue.month-1, dateValue.day).toString() == "Invalid Date")
//   {
//     return false;
//   }
//   return true;
// }

export function ConfirmPassword (fg: FormGroup) : ValidationErrors
{
  let orig = fg.get('password').value;
  let confirm = fg.get('confirmPassword').value;

  return orig.localeCompare(confirm) ? { passwordConfirm: true } : null;
}

export function PasswordStrength (fg: FormGroup) : ValidationErrors
{
  let pass = fg.get('password').value;
  let exp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

  return exp.test(pass) ? null : { passwordStrength: true };
}

//Must be at least 8 characters long
export function PasswordLength (fg: FormGroup) : ValidationErrors
{
  let pass = fg.get('password').value;
  let exp = /^.{8,}$/;

  return exp.test(pass) ? null : { passwordLength: true };
}

//Must contain at least 1 upper case letter
export function PasswordUppercase (fg: FormGroup) : ValidationErrors
{
  let pass = fg.get('password').value;
  let exp = /(.*[A-Z]){1,}/;

  return exp.test(pass) ? null : { passwordUppercase: true };
}

//Must contain at least 1 lower case letter
export function PasswordLowercase (fg: FormGroup) : ValidationErrors
{
  let pass = fg.get('password').value;
  let exp = /(.*[a-z]){1,}/;

  return exp.test(pass) ? null : { passwordLowercase: true };
}

//Must contain at least 1 number
export function PasswordNumber (fg: FormGroup) : ValidationErrors
{
  let pass = fg.get('password').value;
  let exp = /(.*[0-9]){1,}/;

  return exp.test(pass) ? null : { passwordNumber: true };
}

//Cannot contain product name variations
export function PasswordProductName (fg: FormGroup) : ValidationErrors
{
  let pass = fg.get('password').value;
  let exp = new RegExp(/[fF][Aa4@][cC][Ii1!][Ll1][Ii1!][Tt7][Aa4@][Tt7][Ee3]/, '');

  return exp.test(pass) ? { passwordProductName: true } : null;
}

//Cannot contain common passwords

export function ConfirmEmail (fg: FormGroup) : ValidationErrors
{
  let orig = fg.get('email').value;
  let confirm = fg.get('confirmEmail').value;

  return orig.localeCompare(confirm) ? { emailConfirm: true } : null;
};

export function IsInList(controlName: string, list: any[]) : ValidationErrors{
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];

        if (control.value != "" && !list.includes(control.value)) {
          control.setErrors({ isInList: true });
        }
    }
}

export function EmailValidator(control) { // a@b.c
  if (control.value) {
    const matches = control.value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/);
    return matches ? null : { 'invalidEmail': true };
  } else {
    return null;
  }
}
