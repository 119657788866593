import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { UserAvatarComponent } from './user-avatar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
	declarations: [
		UserAvatarComponent
	],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		FormsModule,
		NgbModule
	],
	exports:[
		UserAvatarComponent
	]
})
export class UserAvatarModule { }
