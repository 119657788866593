import { Component, OnInit, ViewChild, ElementRef, HostListener, Output, EventEmitter } from '@angular/core';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { fromEvent } from 'rxjs';
import { filter, debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { Article, NewsItem } from 'src/app/models/help.model';
import { HelpService } from 'src/app/services/help.service';
import { UserProfile } from 'src/app/models/userprofile.model';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Router, NavigationEnd } from '@angular/router';
import { version } from 'package.json';
import { Organisation } from 'src/app/models/organisation.model';
import { AccountService } from 'src/app/services/accounts/account.service';

const HELP_LINKS: {}[] = [
	{ link: "/help/get-started", title: "Get Started", tooltip: "", permissions:["Super Admin","Admin","Facilitator", "Trainee"] },
	{ link: "/help/get-started/tutorials", title: "Tutorials", tooltip: "", permissions:["Super Admin","Admin","Facilitator"] },
	{ link: "/help", title: "Help Center", tooltip: "", permissions:["Super Admin","Admin","Facilitator", "Trainee"]},
	{ link: "/help/troubleshooting", title: "Troubleshooting", tooltip: "", permissions:["Super Admin","Admin","Facilitator", "Trainee"]},
	{ link: "/help/contact-us", title: "Contact Us", tooltip: "", permissions:["Super Admin","Admin","Facilitator", "Trainee"]},
	{ link: "/help/downloads", title: "Download the Facilitate apps", tooltip: "", permissions:["Super Admin","Admin","Facilitator", "Trainee"]}
  ];

@Component({
	selector: 'app-helpbar',
	templateUrl: './helpbar.component.html',
	styleUrls: ['./helpbar.component.css'],
	providers: [NgbTooltipConfig],
	animations: [
		trigger('pageSlideLeft', [
				state('left', style({left: '0', display: 'block'})),
				state('right', style({left: '-100%', display: 'none'})),
				transition('left => right', [
					style({transform: 'translateX(0%)'}),
					animate('500ms ease-in-out', style({transform: 'translateX(calc(-100%))'}))
				]),
				transition('right => left', [
					style({transform: 'translateX(0%)'}),
					animate('500ms ease-in-out', style({transform: 'translateX(calc(100%))'}))
				])
		]),
		trigger('pageSlideRight', [
				state('left', style({left: '100%', display: 'none'})),
				state('right', style({left: '0', display: 'block'})),
				transition('left => right', [
					style({transform: 'translateX(0%)'}),
					animate('500ms ease-in-out', style({transform: 'translateX(calc(-100%))'}))
				]),
				transition('right => left', [
					style({transform: 'translateX(0%)'}),
					animate('500ms ease-in-out', style({transform: 'translateX(calc(100%))'}))
				])
		])
	]
})

export class HelpbarComponent implements OnInit {

	version: string = version;

	whatsnew: NewsItem[] = this.helpService.WHATSNEW;

	@HostListener('document:click', ['$event'])
	clickOut(event: any) : void {};

	@ViewChild('sidebarsearch') sidebarsearch: ElementRef;

	@Output() setHelpNotificationCount = new EventEmitter<number>();

	currentUser: UserProfile;
	currentOrg: Organisation
	whatsnewNotification: number = 0;

	open: boolean = false;
	links: {}[] = [];

	searchValue: string = "";
	searchResults: Article[] = [];

	slideState: string = 'left';

	constructor(public accService: AccountService, tooltipConfig: NgbTooltipConfig, public helpService: HelpService, private router: Router) {
		tooltipConfig.openDelay = 250;
		this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) { // updates if user changes since navbar only init's once per browser session!
				this.currentUser = this.accService.currentUser;
				this.accService.getCurrentOrg().then((result) => {
					this.currentOrg = result;
				});
				this.getNewsCount();
            }
        });
	}

	ngOnInit() {
		this.links = HELP_LINKS;
	}

	ngAfterViewInit() {
		if(this.sidebarsearch)
		{
			fromEvent(this.sidebarsearch.nativeElement,'keyup')
			.pipe(
				filter(Boolean),
				debounceTime(250),
				distinctUntilChanged(),
				tap((text) => {
					this.searchValue = this.sidebarsearch.nativeElement.value;
					this.searchResults = this.helpService.searchArticles(this.searchValue);
					if(!this.currentUser)
					{
						this.searchResults = this.searchResults.filter(x => !x.loginReq);
					}
					else 
					{
						if(this.currentUser.role == "Trainee")
						{
							this.searchResults = this.searchResults.filter(x => !x.traineeGuard);
						}
						else if(this.currentUser.role == "Facilitator")
						{
							this.searchResults = this.searchResults.filter(x => !x.facilitatorGuard);
						}
						
						if(this.currentOrg && !this.currentOrg.isMasterOrg)
						{
							this.searchResults = this.searchResults.filter(x => !x.masterOrgGuard);
						}

						if(this.currentOrg && !this.currentOrg.isResellerOrg)
						{
							this.searchResults = this.searchResults.filter(x => !x.resellerGuard);
						}
					}					
				})
			).subscribe();
		}
	}

	public getNewsCount() {
		if(this.currentUser)
		{
			this.helpService.getNewsReadCount(this.currentUser.uid)
			.then((result) => {
				if(this.whatsnew.length > result)
				{
					this.whatsnewNotification = this.whatsnew.length - result;	
					this.setHelpNotificationCount.emit(this.whatsnewNotification);
				}
				else
				{
					this.whatsnewNotification = 0;
					this.setHelpNotificationCount.emit(this.whatsnewNotification);
				}
			});	
		}
	}

	public toggle(force: boolean = null) {
		if(force != null)
		{
			this.open = force;
		}
		else
		{
			this.open = !this.open;
		}
		
		if(this.open)
		{
			this.clickOut = function(event: any) : void {
				if(this.open)
				{
					// auto close help sidebar if click is not in the sidebar or the help button
					if(!document.getElementById('helpbar-container').contains(event.target) && !document.getElementById('help-icon').contains(event.target))
					{
						this.open = false;
						this.slideState = 'left';
						this.sidebarsearch.nativeElement.value = "";
						this.searchValue = "";
					}
				}
			}
		}
		else
		{
			this.clickOut = function() : void {};
		}
	}

	public viewedNew() {
		this.whatsnewNotification = 0;
		this.setHelpNotificationCount.emit(this.whatsnewNotification);
		this.helpService.setNewsReadCount(this.currentUser.uid, this.whatsnew.length);
	}

	public goToHelpLink(section: string, link: string) {
		this.toggle();
		window.open("/help/"+section+"/"+link, "_blank");
	}
}
