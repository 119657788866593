import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { GroupSideBarComponent } from './group-side-bar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
	declarations: [
		GroupSideBarComponent
	],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		FormsModule,
		NgbModule
	],
	exports:[
		GroupSideBarComponent
	]
})
export class GroupSideBarModule { }
